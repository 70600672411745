import React from 'react';
import { connect } from 'react-redux';
import {
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Col,Row,Form
} from 'reactstrap';
import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';

const filterCaseInsensitive = (filter, row) => {
	const id = filter.pivotId || filter.id;
	if (row[id] !== null){
		return (row[id] !== undefined ? String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase()) : true );
	}
};
class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			articulo:{
				articulo_id:0,
				cantidad:'',
				costo:'',
				pedimento:0,
				serie:0,
				series:[],
			},
			input_serie:'',
			indice_serie:null,
			action:'save',
			submit:false,
		};  
		this.handeInputChange = this.handeInputChange.bind(this);
		this.handleInputChangestate = this.handleInputChangestate.bind(this);
		this.toggle_modal = this.toggle_modal.bind(this);
		this.retorno = this.retorno.bind(this);
		this.ChangeCode = this.ChangeCode.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.push = this.push.bind(this);
		this.delete = this.delete.bind(this);
		this.regresar = this.regresar.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(evt){
		evt.preventDefault();
		let _self = this;
		let {articulo} = this.state;
		_self.setState({
			submit : true
		})
        //console.log('articulo--->',articulo)
        if(parseInt( articulo.articulo_id)>0 )
        {
        	if( (parseInt(articulo.serie)==1&&articulo.series.length>0&&parseInt(articulo.cantidad)==articulo.series.length) ||parseInt(articulo.serie)==0){
        		articulo.descripcion=this.state.label_articulo_id;
        		articulo.codigo=this.state.codigo_articulo_id;
        		this.props.retorno(articulo);
        	}else{
        		swal.fire("Oops..", "Favor de Verificar las series tienen que ser igual a la cantidad ingresada");
        		_self.setState({
        			submit : false
        		})
        	}

        }else{
        	swal.fire("Oops..", "Favor de ingresar un articulo ");
        	_self.setState({
        		submit : false
        	})
        }
    }

    editar(evt,index){
    	let {articulo} = this.state;  
    	this.setState({
    		input_serie : articulo.series[index].serie,
    		indice_serie:index,
    		action:'update'
    	});
    }

    regresar( ){
    	this.setState({
    		input_serie:'',
    		indice_serie:null,
    		action:'save',
    	});
    }
    delete(evt,index){
    	let {articulo} = this.state;   
    	let submit=true;
    	/*peticion para agregar*/
    	let self = this;
    	swal.queue([{
    		title: 'Favor de Confirmar ',
    		text: " Se eliminar La serie: ",
    		type: 'warning',
    		showCancelButton: true,
    		confirmButtonColor: '#3085d6',
    		cancelButtonColor: '#d33',
    		confirmButtonText: 'Confirmar!',
    		cancelButtonText: 'Cancelar!',
    		showLoaderOnConfirm: true,
    		preConfirm: () => {
    			articulo.series.splice(index,1);
    			self.setState({
    				articulo:articulo,
    			});
    		}
    	}])
    }


    push(objeto){
    	let {articulo,input_serie,indice_serie} = this.state;
    	let self = this;
    	let modal=false;
    	/*verificamos que la serie no este en el arreglo*/
    	for (var i = articulo.series.length - 1; i >= 0; i--) {
    		console.log('serie:',articulo.series[i].serie)
    		if(objeto.serie==articulo.series[i].serie){
    			modal=true;
    		}
    	}
    	if(modal){
    		swal.fire("Oops..", "La serie ya se encuentra en la lista");
    	}else{
    		articulo.series.push({
    			id:null,
    			serie:objeto.serie,
    		});
    		this.setState({
    			articulo:articulo,
    			input_serie:'',
    			indice_serie:null,
    			action:'save',
    			modal:modal,     
    		});
    	}
    }


    handleClose (event,tabla,campo) {

    	const target    = event.target;
    	const value     = target.type === 'checkbox' ? target.checked : target.value;
    	const name      = target.name;
    	let self =this;
    	if(value!=""){
    		api().get(`${tabla}/${value}`)
    		.then(function(response)
    		{                  
    			if(response.status === 200)
    			{ 
    				let vaciar=0;
    				if(Object.keys(response.data).length > 0){


    				}  else{
    					vaciar=1
    					swal.fire("Oops..", "No Existe el Codigo");
    				}
    				self.retorno(response.data,campo,vaciar,value);        

    			}

    		});
    	}else{
    		self.retorno([],campo,1,value); 
    	}


    }
    ChangeCode(event) {

    	const target    = event.target;
    	const value     = target.type === 'checkbox' ? target.checked : target.value;
    	const name      = target.name;
    // console.log('name---->',name);
    // console.log('value---->',value);
    this.setState({
    	[name] : value
    });
}

retorno(objeto,campo,vacio=0,valor=""){
	let label="";
	let codigo="";
	let id="";
	let pedimento=0;
	let serie=0;
	  let unidad='';
	  let costo=0;
	let {articulo} = this.state;
	let self =this;
	console.log('retorno---->',objeto)
	if(vacio==0){
		label=objeto.descripcion;
		codigo=objeto.codigo;
		id=objeto.id;
		pedimento=objeto.pedimento;
		serie=objeto.serie;
		unidad=objeto.unidad;
		costo=objeto.costo
	}

	self.setState({
		articulo: {
			...this.state.articulo,
			[campo]: id,
			cantidad:objeto.cantidad,
			pedimento:pedimento,
			unidad:unidad,
			costo:costo,
			serie:serie,
		},         
		['label_'+campo]:label,
		['codigo_'+campo]:codigo,
		modal:false,         
	})
}

toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  

	this.setState({
		modal : !this.state.modal,
		titulo:catalogo,
		tabla_modal:index,
		campo_modal:campo,
		tipo_modal:tipo,
		nivel_modal:nivel,         
	});
}
handeInputChange(event){
	let {articulo} = this.state;
	const target = event.target;
	const value = target.type === 'checkbox' ? target.checked : target.value;
	const name = target.name;

	articulo[name] = value;
    // console.log('articulo handeInputChange---->',articulo)
    this.setState({
    	articulo : articulo
    });
}

handleInputChangestate(event){

	const target = event.target;
	const value = target.type === 'checkbox' ? target.checked : target.value;
	const name = target.name;


    // console.log('articulo handeInputChange---->',articulo)
    this.setState({
    	[name]: value,
    });
}







componentDidMount()
{
	let cliente=this.props.cliente;
	let self=this;

	if(this.props.indice!=null){
		this.setState({
			articulo: this.props.renglon,
			codigo_articulo_id:this.props.renglon.codigo,
			label_articulo_id:this.props.renglon.descripcion,
		});
	}else{
		this.setState( {
			articulo:{
				articulo_id:0,
				cantidad:'',
				costo:'',
				pedimento:0,
				serie:0,
				series:[],

			}
		});
	}
}



render()
{

	let {articulo}=this.state;
	console.log('articulo----->',articulo)
	return(

		<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default modal-lg">
		<ModalHeader toggle={this.props.toggle}>Articulo {this.props.name_almacen}</ModalHeader>
		<ModalBody>
		<Form onSubmit={this.handleSubmit}>
			<Row className='justify-content-center '>
				<div className=" col-8 text-right">  
					<Button color="primary"  type="submit" disabled={this.state.submit} onClick={this.props.submit}>Guardar</Button>
					<Button color="secondary" onClick={this.props.toggle}>Cerrar</Button>
				</div>
				<Row className=' col-8'>
					<Col sm='10' md="3">
						<div className="form-group row">
							<div className="col-12 p-0">
								<label  className=" col-form-label">Articulo * : </label>
								<input type="text" className="form-control" 
									name="codigo_articulo_id"
									required
									value={this.state.codigo_articulo_id}
									onChange={this.ChangeCode} 
									onKeyPress={event => {
										if (event.key === 'Enter') {
											event.preventDefault();
											this.handleClose(event,`/getarticuloalmacen/codigo/${this.props.origen}`,"articulo_id")
										}
									}}
								/>
							</div>
						</div>
					</Col>
					<Col sm='2' md="1" className='pt-2'>
						<Button	className="btn btn_catalogos he" color="secondary"	style={{bottom: '18px', position: 'absolute'}}	onClick={ (button) => { this.toggle_modal(23,"Articulos","articulo_id") } }>+</Button>
					</Col>
					<div className="form-group col-12 col-md-4 ">
						<label  className=" col-form-label">Cantidad : </label>
						<input type="number" className="form-control" 
							name="cantidad"
							required
							step='0.01'
							value={articulo.cantidad}
							onChange={this.handeInputChange}
						/>
					</div>
					<div className="form-group col-12 col-md-4 ">
						<label  className=" col-form-label">Costo : </label>
						<input type="number" className="form-control" 
							name="costo"
							required
							value={articulo.costo}
							onChange={this.handeInputChange}
						/>
					</div>
					<Col md="12" className='  fondo_label' style={{ marginTop:'0px', height:'50px'}}>
						<label style={{marginTop:'15px'}} >{this.state.label_articulo_id} </label>
					</Col>
				</Row>
			</Row>
			<div className="row justify-content-center">
			</div>
		</Form>
		{articulo.serie ==1 &&
			<div className='row justify-content-center'>
				<div className='col-8'>
					<div className='row'>
						<div className="col-md-12 text-right">
							<div className="form-group">
								{articulo.serie ==1 &&
									<Button color="primary" className="" 
									disabled={this.state.submit}
									style={{    position: 'relative', bottom: '-13px'}}
									onClick={ (button) => { this.toggle_modal(20,"Series","articulo_id") } }>
									Buscar
									</Button>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		} {articulo.serie ==1 &&
			<div className="row justify-content-center">
				<div className="col-12 col-md-8"> 
					<ReactTable
					data      = {articulo.series}
					className = "-striped -highlight"
					columns   = {[
						{
							Header: 'Controles',
							filterable: false,
							sortable: false,
							maxWidth:250,minWidth:250,
							Cell: (row) =>
							{
								return(
									<div className="text-left">
									<Button
									color="danger" 
									className='m-0 p-2 px-3'

									onClick={(evt)=>this.delete(evt, row.index)}>
									Eliminar
									</Button> 
									</div>
									)
							}
						},

						{Header: 'Serie', accessor: 'serie',minWidth:250},
						]}
						filterable 
						pageSizeOptions= {[5]}  defaultPageSize={5} 
						defaultFilterMethod={filterCaseInsensitive}
						/>        
					</div>
				</div>
			}
			</ModalBody>
			<ModalFooter>
			</ModalFooter>
			{
				this.state.modal&&
					<ModalTablas
					open={this.state.modal}
					toggle={this.toggle_modal}                  
					titulo={this.state.titulo}
					catalogo={this.state.tabla_modal}
					retorno={this.retorno}
					campo={this.state.campo_modal}
					nivel={this.state.nivel_modal}



					tipo={parseInt(this.props.tipo)}
					origen={parseInt(this.props.origen)}
					documento={parseInt(this.props.documento)}
					retornoserie={this.push}
					articulo={parseInt(this.state.articulo.articulo_id)}

					/>
			}
			</Modal>
			);
}
}

function mapStateToProps(state, ownProps) {
	return { 
		auth : state.auth,
	}
};

export default connect(mapStateToProps, actions)(TicketModal)