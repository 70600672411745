import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Col,Row,Form,UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';

class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
              articulo:{
                    articulo_id:0,
                    cantidad:1,
                    costo:'',
                    pedimento:0,
                    serie:0,
                    series:[],
                    ieps:'',
                    cuota_fija_ieps:0,
                    cuota_fija_otro:0,
                    impuesto_otro:'',
                    cantidad_fiscal:0,
                    iva:'',
                    retencion_isr:'',
                    retencion_iva:'',
                    excento_iva:0,
                    descuento:0,
                    salida:0,
                    sin_existencia:0,                   
              },
              info:{
                precio1:0,
                precio2:0,
                precio3:0,
                precio4:0,
                precio5:0,
                precio6:0,

              },
              input_serie:'',
              indice_serie:null,
              action:'save',
              submit:false,
        };  
        this.handeInputChange = this.handeInputChange.bind(this);
        this.handleInputChangestate = this.handleInputChangestate.bind(this);
      
      
      
        this.handleSubmit = this.handleSubmit.bind(this);
	}





 



  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo} = this.state;
    _self.setState({
            submit : true
          })
    //console.log('articulo--->',articulo)


    if( parseFloat(articulo.abono_documento)<=parseFloat(articulo.saldo_numero)){
      

     
      

      

       this.props.retorno(articulo);
    }else{
      swal.fire("Oops..", "Favor de Verificar la cantidad de pago  ya que  excede el saldo del documento ");
      _self.setState({
            submit : false
          });
    }


  
    //console.log('submit');





  }






 calculos=(pago)=> {
   let {articulo} = this.state;
  /** validamos  que sea un dato numerico el que este en el input**/
   // console.log('el numero es --->',pago.length)
   if (isNaN(pago) || pago.length==0) {
    // console.log('no es numero')
  }else{
    /*verificamos que los tipos de monedas sean diferentes pago y documento*/
    if(this.props.moneda==articulo.moneda){
          articulo.abono_documento=pago;
          
    }else{

      /*verificamos el tipo de moneda del pago*/
      if(this.props.moneda=="USD"){
        /*aqui pondremos  que se esta pagando dolares a un documento en pesos */
        articulo.abono_documento=pago*this.props.tc;

      }else{
        /*aqui pondremos que se estan pagando pesos  a un documento en dolares*/
        articulo.abono_documento=pago/this.props.tc;
      }

    }
    this.setState({
            articulo : articulo
          });
   
  }

}


 handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)

this.calculos(value);
    this.setState({
        articulo : articulo
    });
  }

  handleInputChangestate(event){
   
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

   
// console.log('articulo handeInputChange---->',articulo)


    this.setState({
        [name]: value,
    });
  }
    componentDidMount()
    {
        let cliente=this.props.cliente;
        let self=this;

        console.log('props---->',this.props)


        
  
          this.setState({
              articulo: this.props.renglon,
             

          });
   
    }



	render()
	{

     let {articulo}=this.state;
     console.log('documento modal--->',articulo)
		
		return(

			<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default modal-lg">
				
                <ModalHeader toggle={this.props.toggle}>Docuemtno</ModalHeader>
                <ModalBody>

                <Form onSubmit={this.handleSubmit}>

                    <Row className='justify-content-center '>
                            <div className=" col-8 text-right">  
                      <Button color="primary"  type="submit"
                          disabled={this.state.submit} onClick={this.props.submit}>
                          Guardar
                        </Button>
                      
                        <Button color="secondary" onClick={this.props.toggle}>
                          Cerrar
                        </Button>
                      </div>
                      </Row>
                       <Row className='justify-content-center '>
                         <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Folio : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={articulo.folio}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Total Documento : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={articulo.total}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Saldo Documento : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={articulo.saldo}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Moneda Documento : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={articulo.moneda}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">TC Documento : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={articulo.tc}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Abono Documento : </label>
                              <input readOnly type="number" className="form-control" name="tc" value={articulo.abono_documento} />
                          </div>
                      </Row>
                      <Row className=' '>
                         
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">TC Pago : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={this.props.tc}/>
                          </div>
                           <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Moneda Pago : </label>
                              <input readOnly type="text" className="form-control" name="folio"   required value={this.props.moneda}/>
                          </div>
                          <div className="form-group col-12 col-md-2 ">
                            <label  className=" col-form-label">Pago : </label>
                              <input type="number" className="form-control" 
                                name="total_abono"
                                step={'0.01'}
                                required
                                value={articulo.total_abono}
                                onChange={this.handeInputChange}
                               />
                          </div>
                      </Row>
                          


 

                          

                            </Form>  
                          </ModalBody>
                          <ModalFooter>
                    

                   
                </ModalFooter>
       
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
         auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(TicketModal)