import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/clientes';
import {api } from '../../actions/_request';
import swal from 'sweetalert2'
import ReactTable from "react-table";
import "react-table/react-table.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText,Label
} from "reactstrap";
import classnames from 'classnames';
import Select from 'react-select';
import ModalTablas from '../../components/catalogos/modal_tablas';
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };
  const monedas = [
    {label: 'MXN', value: 'MXN'},
    {label: 'USD', value:'USD'},
    
];

const precios = [
    {label: 'Precio 1', value: 'precio1'},
    {label: 'Precio 2', value: 'precio2'},
    {label: 'Precio 3', value: 'precio3'},
    {label: 'Precio 4', value: 'precio4'},
    {label: 'Precio 5', value: 'precio5'},
    {label: 'Costo', value: 'precio6'},
   
    
];
class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      cliente:{
        id:'',
        cliente:'',
        telefono:'',
        razon_social:'',
        domicilio:'',
        ciudad:'',
        codigo_postal:'',
        contacto:'',
        rfc:'',
        limite_credito:0,
        factura_registro_fiscal:'',
        banco_id:0,
        cuenta:'',
        activo:0,
        credito:0,
        exceder_credito:0,
        factura_metodo:'PUE',
        factura_forma:'01',
        factura_uso:'G03',
        factura_pais:'MEX',
        factura_moneda:'MXN',
        lista_precios:'precio1',
        desglosar_ieps:0
      },
      action : 'save',
      submit:false,
      activeTab: '1',
      cc:[],
      
      
      cc_text:'',
      modal:false,
      formas : [],
      metodos : [],
      usos : [],
      codigo_pais:'MEX',


       contactos:[],
       contacto_text:'',
       telefono_text:'',
       index_contacto:null,

       cuentas:[],
       cuenta_text:'',
       index_cuenta:null,
       clave_banco:'',
       nombre_banco:'',
       rfc_banco:'',

      
      
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
     this.handleChangerfc = this.handleChangerfc.bind(this);
     this.toggle_tab = this.toggle_tab.bind(this);
     this.delete = this.delete.bind(this);
     this.delete_contacto = this.delete_contacto.bind(this);
     this.push = this.push.bind(this);
     this.push_contacto = this.push_contacto.bind(this);
     this.toggle_modal = this.toggle_modal.bind(this);
     this.ChangeCode= this.ChangeCode.bind(this);
     this.handleClose= this.handleClose.bind(this);
     this.retorno= this.retorno.bind(this);
     this.handleInputChangecorreo= this.handleInputChangecorreo.bind(this);
     this.handleSelectChange= this.handleSelectChange.bind(this);
  }

  

  editar_cuenta=(evt,index)=>{
     this.setState({
      clave_banco:this.state.cuentas[index].clave_banco,
      rfc_banco:this.state.cuentas[index].rfc_banco,
      nombre_banco:this.state.cuentas[index].nombre_banco,
      cuenta_text:this.state.cuentas[index].cuenta,
      cliente: {
                ...this.state.cliente,
                banco_id: this.state.cuentas[index].banco_id,
            },
      index_cuenta : index,
    });


  }

  delete_cuenta=(evt,index)=>{
    let {cuentas} = this.state;  
    cuentas.splice(index,1);
    this.setState({
      cuentas : cuentas,
    });
  }

  push_cuentas = ()=>{
       let {cuentas,cuenta_text,clave_banco,rfc_banco,nombre_banco,index_cuenta,cliente} = this.state;
       var patt = new RegExp("[a-zA-Z0-9]+", "i");
       console.log('state--->',this.state)
        // Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (patt.test(clave_banco)&&patt.test(cuenta_text)&&parseInt(cliente.banco_id)>0) {
            if(index_cuenta!=null){
              cuentas[index_cuenta]={
                clave_banco : clave_banco,
                nombre_banco : nombre_banco,
                rfc_banco : rfc_banco,
                cuenta : cuenta_text,
                banco_id : cliente.banco_id,
              };
            }else{
              cuentas.unshift({
                 clave_banco : clave_banco,
                nombre_banco : nombre_banco,
                rfc_banco : rfc_banco,
                cuenta : cuenta_text,
                banco_id : cliente.banco_id,
              });
            }
          this.setState({
            cuentas : cuentas,
            clave_banco: '',
            nombre_banco:'',
            rfc_banco:'',
            cuenta_text:'',
            cliente: {
                ...this.state.cliente,
                banco_id: 0,
            },
            index_cuenta:null,
          });

           swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
           swal.fire("Oops..", "Informacion de la Cuenta invalida ");
        }
     
   }

editar_contacto=(evt,index)=>{
     this.setState({
      contacto_text:this.state.contactos[index].contacto,
      telefono_text:this.state.contactos[index].telefono,
      index_contacto : index,
    });


  }
  
  push_contacto(){
       let {contactos,contacto_text,telefono_text,index_contacto} = this.state;
       var patt = new RegExp("[a-zA-Z0-9]+", "i");
       console.log('state--->',this.state)
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (patt.test(contacto_text)&&patt.test(telefono_text)) {
            if(index_contacto!=null){
              contactos[index_contacto]={
                contacto : contacto_text,
                telefono : telefono_text,
              };
            }else{
              contactos.unshift({
                contacto : contacto_text,
                telefono : telefono_text,
              });
            }
          this.setState({
            contactos : contactos,
            contacto_text: '',
            telefono_text:'',
            index_contacto:null,
          });

           swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
           swal.fire("Oops..", "Informacion de Contacto invalida ");
        }
     
   }


  delete_contacto(evt,index){
    let {contactos} = this.state;  

    contactos.splice(index,1);
    this.setState({
      contactos : contactos,
    });
  }


  handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
            cliente: {
                ...this.state.cliente,
                [name]: value
            }
        });
    }
    handleInputChangecorreo(event) {
        let {impuesto} = this.state;
        const target    = event.target;
        let value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;

        this.setState({
           [name] : value,
        });
    }
   retorno(objeto,campo,vacio=0,valor=""){
        let codigo="";
        let campo_codigo="";
        console.log('renglon---->',objeto);
        let {cliente} = this.state;
        let self =this;
         let rfc_banco="";
        let nombre_banco="";
        switch (campo) {
            case "lab":
                  codigo=objeto.value?objeto.value:objeto.id;
                  campo_codigo="codigo_lab"; 
                  cliente[campo] = objeto.value?objeto.value:objeto.id;
                  break;
            case "via_emb":
                  codigo=objeto.value?objeto.value:objeto.id;
                  campo_codigo="codigo_via_emb";
                  cliente[campo] = objeto.value?objeto.value:objeto.id;
                  break;
            case "codigo_descuento":
                  codigo=objeto.value?objeto.value:objeto.id;
                  campo_codigo="codigo_descuento"; 
                  cliente[campo] = objeto.value?objeto.value:objeto.id;
                  break;
            case "term_pago":
                  codigo=objeto.value?objeto.value:objeto.id;
                  campo_codigo="codigo_terminos";
                  cliente[campo] = objeto.value?objeto.value:objeto.id;
                  break;
            case "factura_pais":
                  codigo=objeto.codigo?objeto.codigo:objeto.id;
                  campo_codigo="codigo_pais";
                  cliente[campo] = objeto.codigo?objeto.codigo:objeto.id;
                  break;
            case "banco_id":
                  codigo=objeto.clave;
                  campo_codigo="clave_banco"; 
                  cliente[campo] = objeto.id;
                  rfc_banco=objeto.rfc;
                  nombre_banco=objeto.nombre;
                  break;           
        }  
       if(vacio==1){
        console.log('vaciar')
        codigo=valor;
        cliente[campo] =0;
          rfc_banco="";
         nombre_banco="";
       }
       if(campo=="banco_id"){
        self.setState({
            cliente : cliente,           
            [campo_codigo]:codigo,
            modal:false, 
            rfc_banco:rfc_banco,
            nombre_banco:nombre_banco,    
        })

       }else{
        self.setState({
            cliente : cliente,           
            [campo_codigo]:codigo,
            modal:false,         
        })
       }
       
    
        // self.setState({
        //     cliente : cliente,           
        //     [campo_codigo]:codigo,
        //     modal:false,         
        // })


    }  
  handleClose (event) {
         
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        
        let campo="";
        let tabla=""

        
        switch (name) {
            case "codigo_lab":                 
                  campo = "lab";
                  tabla="/lab";
                  break;
            case "codigo_via_emb":                  
                  campo = "via_emb";
                  tabla="/embarque";
                  break;
            case "codigo_descuento":                 
                  campo = "codigo_descuento";
                  tabla="/descuentof";
                  break;
            case "codigo_terminos":                  
                  campo = "term_pago";
                  tabla="/terminos_pago";
                  break; 
            case "codigo_pais":                  
                  campo = "factura_pais";
                  tabla="/sat/paises_codigo";
                  break;
            case "clave_banco":                 
                  campo = "banco_id";
                  tabla="/sat/banco_codigo";
                  break; 
 
        }
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }

    ChangeCode(event) {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
     
        this.setState({
            [name] : value
        });
    }
  toggle_modal(index=0,catalogo="",campo=""){  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,         
        })
    }
  push(){
    let {cc,cc_text} = this.state;
    if(cc_text.length>0){
        // 
        let emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //Se muestra un texto a modo de ejemplo, luego va a ser un icono
        if (emailRegex.test(cc_text)) {
          cc.unshift({
            correo : cc_text,
          });
          this.setState({
            cc : cc,
            cc_text:'',
          });
          swal.fire("Exito", "Se Guardo Con Exito ");
        } else {
         swal.fire("Oops..", "Correo Invalido ");
       }
     }
   }
  delete(evt,index){
    let {cc} = this.state;  

    cc.splice(index,1);
    this.setState({
      cc : cc,
    });
  }
  toggle_tab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  handleChangerfc()
  {
    let {cliente}         = this.state;
    cliente.rfc    = "XAXX010101000";
    this.setState({cliente: cliente});
  }

  componentDidMount(){
    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;
      let self = this;
         api().get('/formulario_cliente')
        .then(function(response)
        {
            self.setState({
                metodos : response.data.metodos,
                formas : response.data.formas,
                usos : response.data.usos,
                
            });
           
        })




    if(params.id) {
      api().get("/getcliente/"+params.id)
      .then(function(response){
        if(response.status === 200){
          let data = response.data;
          _self.setState({
            codigo_pais:data.cliente.factura_pais,

            cliente : data.cliente,
            cc:data.cc,
            cuentas:data.bancos,
            contactos:data.contactos,
            action : 'update'
          })
        }
      });
    }
  }


  handeInputChange(event){
    let {cliente} = this.state;
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    if(target.type === 'checkbox'){
      var bandera=0;
      if(value){
        bandera=1;
      }
      value=bandera;

    }

    cliente[name] = value;
    this.setState({
      cliente : cliente
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {cliente,action,cc,cuentas,contactos} = this.state;
    let save = true;
    _self.setState({
      submit : true
    });
    var patt = new RegExp("[a-zA-Z0-9]?[a-zA-Z0-9]{11}[a-zA-Z0-9]$", "i");

    cliente.cc=cc;
    cliente.cuentas=cuentas;
    cliente.contactos=contactos;

    if(cliente.rfc.length>11&&cliente.rfc.search(patt)==0){
      console.log('cliente----->',cliente)


    /*validamos el rfc*/
    this.props.save(cliente, (response) => {
      if(response.status){
        if(response.data.estado){
        // console.log('actualizar')
        swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/clientes');
                        });
        
        }else{
          swal.fire("Alerta!", response.data.mensaje, "warning");
          _self.setState({
            submit : false
          });
        }
      }else{
        console.log('error')
        swal.fire({
          type: 'error',
          title: 'Solicitar ayuda a un Administrador',
        })
        _self.setState({
          submit : false
        });
      }
    });

    }else{
      swal.fire("Oops..", "Favor de revisar el rfc", "warning");
    }

  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/clientes');
  }

  render() {
     let {submit,cliente} = this.state;
     
    return (
      
        <div className="content">
          <Row>
          
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                  <Nav tabs>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.toggle_tab('1'); }}
                        >
                          Datos Cliente
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle_tab('2'); }}
                        >
                          Correos
                        </NavLink>
                      </NavItem>
                       <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle_tab('3'); }}
                        >
                          Contactos
                        </NavLink>
                      </NavItem>
                       <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '4' })}
                          onClick={() => { this.toggle_tab('4'); }}
                        >
                          Cuentas
                        </NavLink>
                      </NavItem>
                    </Nav>
                     <TabContent activeTab={this.state.activeTab}>

                     <TabPane tabId="4">
                     <div className="row mt-3" >
                        <div className="col-md-4"> 
                              <div className="form-group">
                                  <label className="d-block">Clave Banco </label>
                                  <Input
                                  placeholder=""
                                  type="number"
                                  name="clave_banco"
                                  value={this.state.clave_banco}
                                   onChange={ this.ChangeCode}
                                   onBlur={this.handleClose}
                                   onKeyPress={event => {
                    
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event)
                                      }
                                    }}
                                  className="input_button_sm"
                                
                                  />
                                  <Button onClick={ (button) => { this.toggle_modal(0,"Bancos","banco_id") } }>...</Button>
                              </div>
                          </div>
                          <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>Cuenta *</Label>
                                                            <Input
                                                            placeholder=""
                                                            type="text"
                                                            name="cuenta_text"
                                                            value={this.state.cuenta_text}
                                                            onChange={ this.handleInputChangecorreo}
                                                            />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push_cuentas}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>
                       </div>
                        <Row>
                          
                           <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label>Nombre Banco *</Label>
                                                        <Input
                                                        placeholder=""
                                                        type="text"
                                                        name="nombre_emisor"
                                                        
                                                        value={this.state.nombre_banco}
                                                        onChange={ this.handleInputChange}
                                                        readOnly
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-3">
                                                    <FormGroup>
                                                        <Label>RFC Banco *</Label>
                                                            <Input
                                                            placeholder=""
                                                            type="text"
                                                            name="rfc_emisor"
                                                            
                                                            value={this.state.rfc_banco}
                                                            onChange={ this.handleInputChange}
                                                            readOnly
                                                            />
                                                    </FormGroup>
                                                </div>
                                                 
                            

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.cuentas}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:160,minWidth:160,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete_cuenta(evt, row.index)}>
                                                    X
                                                </Button> 
                                                <Button
                                                 color="primary" 
                                                 onClick={(evt)=>this.editar_cuenta(evt, row.index)}>
                                                    editar
                                                </Button> 
                                                
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Banco', accessor: 'nombre_banco'},
                                {Header: 'Cuenta', accessor: 'cuenta'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>
                     <TabPane tabId="3">
                        <Row>
                          
                           <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Contacto </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="contacto_text"
                                    maxLength={70}
                                    value={this.state.contacto_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                               <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Telefono </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="telefono_text"
                                    maxLength={70}
                                    value={this.state.telefono_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push_contacto}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.contactos}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:150,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete_contacto(evt, row.index)}>
                                                    X
                                                </Button> 
                                                <Button
                                                 color="primary" 
                                                 onClick={(evt)=>this.editar_contacto(evt, row.index)}>
                                                    editar
                                                </Button> 
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Contacto', accessor: 'contacto'},
                                {Header: 'Telefono', accessor: 'telefono'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>

                     <TabPane tabId="1">
                      <Row>
                  <div className="col-md-3">
                            <FormGroup>
                                <Label>Codigo *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="codigo"
                                    value={cliente.codigo}
                                    onChange={ this.handeInputChange}
                                   required
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-4 pt-3">
                                    <div className="form-group mt-3">
                                    <label className='mr-4' > 
                                        <input type="checkbox"
                                        name="activo" 
                                         checked={parseInt(cliente.activo)==0?false:true}
                                        onChange={this.handeInputChange}
                                        />&nbsp;&nbsp;Activo
                                    </label>
                               
                                    <label className='mr-4' > 
                                        <input type="checkbox"
                                        name="credito" 
                                         checked={parseInt(cliente.credito)==0?false:true}
                                        onChange={this.handeInputChange}
                                        />&nbsp;&nbsp;Credito
                                    </label>
                                    <label className='mr-4' > 
                                        <input type="checkbox"
                                        name="exceder_credito" 
                                         checked={parseInt(cliente.exceder_credito)==0?false:true}
                                        onChange={this.handeInputChange}
                                        />&nbsp;&nbsp;Exceder Credito
                                    </label>
                                    </div>
                                </div>
                                <div className="col-md-8">
                            <FormGroup>
                                <Label>Cliente *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="cliente"
                                    value={cliente.cliente}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                         <div className="col-md-4">
                            <FormGroup>
                                <Label>Limite Credito *</Label>
                                    <Input
                                    placeholder=""
                                    type="tel"
                                    name="limite_credito"
                                    value={cliente.limite_credito}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-12">
                            <FormGroup>
                                <Label>Razon social *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="razon_social"
                                    value={cliente.razon_social}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                         <div className="col-md-12">
                            <FormGroup>
                                <Label>Domicilio *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="domicilio"
                                    value={cliente.domicilio}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-4">
                            <FormGroup>
                                <Label>Ciudad *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="ciudad"
                                    value={cliente.ciudad}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                         <div className="col-md-4">
                            <FormGroup>
                                <Label>Codigo Postal *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="codigo_postal"
                                    value={cliente.codigo_postal}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-md-4">
                            
                        </div>
                        <div className="col-md-3">
                            <FormGroup>
                                <Label>RFC *</Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="rfc"
                                    maxLength={13}
                                    value={cliente.rfc}
                                    onChange={ this.handeInputChange}
                                    required
                                    />
                            </FormGroup>
                        </div>
                        <div className="col-sm-3 pt-3">
                            <div className="form-group mt-3">
                                      <label >
                        <input type="checkbox"
                                name="promovido" 
                                checked={cliente.rfc==="XAXX010101000"?true:false}
                               
                                onChange={this.handleChangerfc}
                         />&nbsp;&nbsp;
                        Publico General
                    </label>
                            </div>
                        </div>
                        
                         <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Moneda:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="factura_moneda"
                                        options={monedas}
                                        value={monedas.filter(option => option.value == cliente.factura_moneda)}
                                        
                                        onChange={ (select) => { this.handleSelectChange(select, 'factura_moneda') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Lista Precios:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="factura_moneda"
                                        options={precios}
                                        value={precios.filter(option => option.value == cliente.lista_precios)}
                                        
                                        onChange={ (select) => { this.handleSelectChange(select, 'lista_precios') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                </Row>

                <Row>
                  <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Forma de Pago:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="factura_forma"
                                        options={this.state.formas}
                                        value={this.state.formas.filter(option => option.value == cliente.factura_forma)}
                                       
                                       onChange={ (select) => { this.handleSelectChange(select, 'factura_forma') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group">
                                    <label className>Metodo de Pago:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="factura_metodo"
                                        options={this.state.metodos}
                                        value={this.state.metodos.filter(option => option.value == cliente.factura_metodo)}
                                        
                                        onChange={ (select) => { this.handleSelectChange(select, 'factura_metodo') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                        
                        <div className="col-md-5"> 
                                <div className="form-group">
                                    <label className>Uso del CFDI:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="factura_uso"
                                        options={this.state.usos}
                                        value={this.state.usos.filter(option => option.value == cliente.factura_uso)}
                                        onChange={ (select) => { this.handleSelectChange(select, 'factura_uso') } }
                                        clearable={false}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="form-group ">
                                    <label className="d-block">Pais:</label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    maxLength={10}
                                    name="codigo_pais"
                                    value={this.state.codigo_pais}
                                    onChange={ this.ChangeCode}
                                    onBlur={this.handleClose}
                                    onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event)
                                      }
                                    }}
                                    className="input_button_sm"
                                    />
                                     <Button onClick={ (button) => { this.toggle_modal(5,"Paises","factura_pais") } }>...</Button>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <FormGroup>
                                <Label>Registro de I. Fiscal
                                </Label>
                                    <Input
                                    placeholder=""
                                    type="text"
                                    name="factura_registro_fiscal"
                                    value={cliente.factura_registro_fiscal}
                                    onChange={ this.handeInputChange}
                                    />
                            </FormGroup>
                        </div>
                </Row>
                <div className="row mt-4 mb-4">
                                        <div className="col-md-12 div_titulo mr-2">
                                         <h1><span>Impuestos y Retenciones:</span></h1>
                                            <div className="row ">
                                       
                                                <div className="col-md-2">
                                                    <FormGroup>
                                                        <Label>I.V.A.</Label>
                                                        <Input
                                                        placeholder=""
                                                        type="number"
                                                        name="iva"
                                                        value={cliente.iva}
                                                        onChange={ this.handeInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-2">
                                                    <FormGroup>
                                                        <Label>I.E.P.S. </Label>
                                                        <Input
                                                        placeholder=""
                                                        type="number"
                                                        name="ieps"
                                                        value={cliente.ieps}
                                                        onChange={ this.handeInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-2 pt-3">
                                                  <div className="form-group mt-3">
                                                    <label className='mr-4' > 
                                                      <input type="checkbox"
                                                        name="desglosar_ieps" 
                                                        checked={parseInt(cliente.desglosar_ieps)==0?false:true}
                                                        onChange={this.handeInputChange}
                                                      />&nbsp;&nbsp;Desglosar I.E.P.S. en CFDI
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <FormGroup>
                                                        <Label>Rentencion I.V.A. </Label>
                                                        <Input
                                                        placeholder=""
                                                        type="number"
                                                        name="retencion_iva"
                                                        value={cliente.retencion_iva}
                                                        onChange={ this.handeInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                 <div className="col-md-2">
                                                    <FormGroup>
                                                        <Label>Rentencion I.S.R. </Label>
                                                        <Input
                                                        placeholder=""
                                                        type="number"
                                                        name="retencion_isr"
                                                        value={cliente.retencion_isr}
                                                        onChange={ this.handeInputChange}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                
                                                 

                                            </div>
                                        </div>
                                    
                                    </div>
                
                     </TabPane>


                    <TabPane tabId="2">
                        <Row>
                          
                           <div className="col-md-4"> 
                                <div className="form-group">
                                    <Label>Correo </Label>
                                    <Input
                                    className='texto_normal'
                                    placeholder=""
                                    type="text"
                                    name="cc_text"
                                    maxLength={70}
                                    value={this.state.cc_text}
                                    onChange={ this.handleInputChangecorreo}
                                    />
                                </div>
                              </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <Button color="primary" className="" 
                                    style={{marginTop: '2rem'}} onClick={this.push}>
                                        Agregar
                                    </Button>
                                </div>
                            </div>

                   
                        </Row>

                        <Row>
                          
                        <div className="col-md-12"> 
                            <ReactTable
                              data      = {this.state.cc}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:100,minWidth:100,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-right">
                                                <Button
                                                 color="danger" 
                                                 onClick={(evt)=>this.delete(evt, row.index)}>
                                                    X
                                                </Button> 
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Correo', accessor: 'correo'},
                                                     
                              
                              ]}
                              filterable 
                             pageSizeOptions= {[5]}  defaultPageSize={5} 
                               defaultFilterMethod={filterCaseInsensitive}
                            />        
                        </div>
                        </Row>
                      </TabPane>
                    </TabContent>

                








                </Col>
                </Row>
                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>

          {this.state.modal&&
            <ModalTablas
                    open={this.state.modal}
                    toggle={this.toggle_modal}                  
                    titulo={this.state.titulo}
                    catalogo={this.state.tabla_modal}
                    retorno={this.retorno}
                    campo={this.state.campo_modal}
               
                    />
            }
        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        clientes:state.clientes
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

