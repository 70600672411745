import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Col,Row,Form
} from 'reactstrap';



import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';
const filterCaseInsensitive = (filter, row) => {
            const id = filter.pivotId || filter.id;
            if (row[id] !== null){
                return (
                    row[id] !== undefined ?
                        String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase())
                        :
                        true
                );
            }
        };




class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
              articulo:{
                    articulo_id:0,
                    cantidad:0,
                   
                    costo:'',
                    pedimento:0,
                    serie:0,
                    costo:0,
                    series:[],
                   
              },
              input_serie:'',
              indice_serie:null,
              action:'save',
              submit:false,
        };  
        this.handeInputChange = this.handeInputChange.bind(this);
        this.handleInputChangestate = this.handleInputChangestate.bind(this);
        this.toggle_modal = this.toggle_modal.bind(this);
        this.retorno = this.retorno.bind(this);
        this.ChangeCode = this.ChangeCode.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.push = this.push.bind(this);
        this.delete = this.delete.bind(this);
        this.regresar = this.regresar.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

	}

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo} = this.state;
    _self.setState({
            submit : true
          })
    //console.log('articulo--->',articulo)
    if(parseInt( articulo.articulo_id)>0 )
{ 
   
      articulo.descripcion=this.state.label_articulo_id;
      articulo.codigo=this.state.codigo_articulo_id;
      console.log('cantidad---->',articulo.cantidad);
      console.log('costo---->',articulo.costo);
      articulo.total=(parseFloat(articulo.cantidad)*parseFloat(articulo.costo)).toFixed(4);
  articulo.insumo_id=articulo.articulo_id;

      //console.log('articulo--->',articulo)
       this.props.retorno(articulo);

    
    }else{
      swal.fire("Oops..", "Favor de ingresar un articulo ");
      _self.setState({
            submit : false
          })
    }
    
    //console.log('submit');





  }

  editar(evt,index){
        let {articulo} = this.state;  
        // console.log('articulo---->',articulo)
        this.setState({
          input_serie : articulo.series[index].serie,
          indice_serie:index,
          action:'update'
        });
    }

  regresar( ){
        this.setState({
          input_serie:'',
          indice_serie:null,
          action:'save'
        });
    }
delete(evt,index){
      let {articulo} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar La serie: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                articulo.series.splice(index,1);
        self.setState({
          
           articulo:articulo,
          });
        }
      }])


         
    }


  push(){
        let {articulo,input_serie,indice_serie} = this.state;
        let self = this;
        let submit=true;
        /*peticion para agregar*/
         this.setState({
           submit:true,
        });
         if(input_serie.trim().length>0){
           if(indice_serie==null){
            /*nueva seerie*/
              articulo.series.push({
                    id:null,
                    serie:input_serie,
              })
           }else{
            /*actualizar serie*/
            articulo.series[indice_serie].serie=input_serie;

           }
           submit=false;
         }else{
          swal.fire("Oops..", "Favor de poner una Serie");
          submit=false;
         }


          this.setState({
           submit:submit,
           articulo:articulo,
           input_serie:'',
          indice_serie:null,
          action:'save'
          });

        


        // console.log('data---->',articulo)

          
        
    }


  handleClose (event,tabla,campo) {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }
  ChangeCode(event) {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        // console.log('name---->',name);
        // console.log('value---->',value);
        this.setState({
            [name] : value
        });
    }

  retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let unidad="";
          let costo="";
        let pedimento=0;
        let serie=0;
        let {articulo} = this.state;
        let self =this;
        // console.log('retorno---->',objeto)
        if(vacio==0){
          label=objeto.descripcion;
          codigo=objeto.codigo;
          id=objeto.id;
          pedimento=objeto.pedimento;
          serie=objeto.serie;
          unidad=objeto.unidad_analisis;
           costo=objeto.precio6;
        }

        self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id,
                pedimento:pedimento,
                serie:serie,
                unidad_analisis:unidad,
                costo:costo
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
    }

  toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
    }
 handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleInputChangestate(event){
   
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

   
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        [name]: value,
    });
  }







    componentDidMount()
    {
        let cliente=this.props.cliente;
        let self=this;

        if(this.props.indice!=null){
          this.setState({
              articulo: this.props.renglon,
              codigo_articulo_id:this.props.renglon.codigo,
              label_articulo_id:this.props.renglon.descripcion,
          });
        }else{
         this.setState( {
                articulo:{
                      articulo_id:0,
                      cantidad:0,
                      costo:'',
                      costo:0,
                      pedimento:0,
                      serie:0,
                      series:[],
                     
                }
              });
        }
    }



	render()
	{

     let {articulo}=this.state;
		
		return(

			<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default modal-lg">
				
                <ModalHeader toggle={this.props.toggle}>Articulo</ModalHeader>
                <ModalBody>

                <Form onSubmit={this.handleSubmit}>

                    <Row className='justify-content-center '>
                            <div className=" col-8 text-right">  
                      <Button color="primary"  type="submit"
                          disabled={this.state.submit} onClick={this.props.submit}>
                          Guardar
                        </Button>
                      
                        <Button color="secondary" onClick={this.props.toggle}>
                          Cerrar
                        </Button>
                      </div>
                            <Row className=' col-8'>
                            <Col sm='10' md="3">
                              <div className="form-group row">
                             
                                <div className="col-12 p-0">
                                <label  className=" col-form-label">Articulo * : </label>
                                  <input type="text" className="form-control" 
                                  name="codigo_articulo_id"
                                  required
                                  value={this.state.codigo_articulo_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getarticulo/codigo","articulo_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos he"
                                  color="secondary"
                                  style={{bottom: '18px', position: 'absolute'}}
                                  onClick={ (button) => { this.toggle_modal(17,"Articulos","articulo_id") } }>
                                  +
                                </Button>
                            </Col>
                            <div className="form-group col-12 col-md-4 ">
                                <label  className=" col-form-label">Cantidad : </label>
                                  <input type="number" className="form-control" 
                                    name="cantidad"
                                    required
                                    step='0.01'
                                    value={articulo.cantidad}
                                    onChange={this.handeInputChange}
                                   />
                            </div>

                            <div className="form-group col-12 col-md-4 ">
                                <label  className=" col-form-label">Costo: </label>
                                  <input type="number" className="form-control" 
                                    name="costo"
                                    required
                                    step='0.001'
                                    value={articulo.costo}
                                    onChange={this.handeInputChange}
                                   />
                            </div>
   
                            <Col md="12" className='  fondo_label' style={{ marginTop:'0px', height:'50px'}}>
                              <label style={{marginTop:'15px'}} >{this.state.label_articulo_id} </label>
                            </Col>
                            </Row>
                            </Row>
                            <div className="row justify-content-center">
                            
                            </div>
                            </Form>
      
                    
                </ModalBody>
                <ModalFooter>
                    

                   
                </ModalFooter>
                {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}
                  
              />
          }
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
         auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(TicketModal)