/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
import Notifications from "views/Notifications.jsx";
import Icons from "views/Icons.jsx";
import Typography from "views/Typography.jsx";
import TableList from "views/Tables.jsx";
import Maps from "views/Map.jsx";
import UserPage from "views/User.jsx";
import UpgradeToPro from "views/Upgrade.jsx";

import Subidas from 'views/Subida';
import SubidaForm from 'views/Subida/subidaForm';

import lista_usuarios from "views/usuarios/lista_usuarios.js";
import formulario_usuario from "views/usuarios/formulario.js";
import Logs from "views/Logs";

import lista_articulos from "views/articulos/lista.js";
import formulario_articulo from "views/articulos/formulario.js";

import lista_proveedores from "views/proveedores/lista.js";
import formulario_proveedores from "views/proveedores/formulario.js";

import lista_almacenes from "views/almacenes/lista.js";
import formulario_almacenes from "views/almacenes/formulario.js";

import lista_compras from "views/compras/lista.js";
import formulario_compras from "views/compras/formulario.js";

import lista_movalmacen from "views/movalmacenes/lista.js";
import formulario_movalmacen from "views/movalmacenes/formulario.js";

import lista_clientes from "views/clientes/lista.js";
import formulario_clientes from "views/clientes/formulario.js";

import lista_ca_proveedores from "views/ca_proveedores/lista.js";
import formulario_ca_proveedores from "views/ca_proveedores/formulario.js";

import lista_facturas from "views/facturas/lista.js";
import formulario_facturas from "views/facturas/formulario.js";
import importar_facturas from "views/facturas/importar.js";

import formulario_abono_clientes from "views/ca_clientes/abono.js";

import formulario_cargo_clientes from "views/ca_clientes/cargo.js";
import formulario_anticipo_clientes from "views/ca_clientes/anticipo.js";
import lista_ca_clientes from "views/ca_clientes/index.js";

import lista_rendimiento from "views/Rendimiento/lista.js";
import formulario_rendimiento from "views/Rendimiento/formulario.js";

import lista_recetas from "views/recetas/lista.js";
import formulario_receta from "views/recetas/formulario.js";

import lista_inv_fisico from "views/inv_fisico/lista.js";
import formulario_inv_fisico from "views/inv_fisico/formulario.js";

// var routes2 = [
//   {
//     path: "/dashboard",
//     name: "Dashboard",
//     icon: "nc-icon nc-bank",
//     component: formulario_movalmacen,
//     layout: "/app"
//   },
//   {
//     path: "/icons",
//     name: "Icons",
//     icon: "nc-icon nc-diamond",
//     component: Icons,
//     layout: "/app"
//   },
//   {
//     path: "/maps",
//     name: "Maps",
//     icon: "nc-icon nc-pin-3",
//     component: Maps,
//     layout: "/app"
//   },
//   {
//     path: "/notifications",
//     name: "Notifications",
//     icon: "nc-icon nc-bell-55",
//     component: Notifications,
//     layout: "/app"
//   },
//   {
//     path: "/user-page",
//     name: "User Profile",
//     icon: "nc-icon nc-single-02",
//     component: UserPage,
//     layout: "/app"
//   },
//   {
//     path: "/tables",
//     name: "Table List",
//     icon: "nc-icon nc-tile-56",
//     component: TableList,
//     layout: "/app"
//   },
//   {
//     path: "/typography",
//     name: "Typography",
//     icon: "nc-icon nc-caps-small",
//     component: Typography,
//     layout: "/app"
//   },
//   {
//     pro: true,
//     path: "/upgrade",
//     name: "Upgrade to PRO",
//     icon: "nc-icon nc-spaceship",
//     component: UpgradeToPro,
//     layout: "/app"
//   }
// ];


var routes = [

  {
    path: "/Dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/app",
    exact: true,
  },
   {
    path: "/usuarios",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
    component: lista_usuarios,
    layout: "/app",
    exact: true,
  },
  {
    path: "/usuarios/nuevo",
    name: "Usuario nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_usuario,
    layout: "/app",
    exact: true
  },
  {
    path: "/usuarios/editar/:id",
    name: "Editar Usuario",
    icon: "nc-icon nc-single-02",
    component: formulario_usuario,
    layout: "/app",
    exact: true
  },
/*Modulo de Articulos*/
  {
    path: "/articulos",
    name: "Articulos",
    icon: "nc-icon nc-app",
    component: lista_articulos,
    layout: "/app",
    exact: true,
  },
  {
    path: "/articulos/nuevo",
    name: "Articulo nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_articulo,
    layout: "/app",
    exact: true
  },
  {
    path: "/articulos/editar/:id",
    name: "Editar Articulo",
    icon: "nc-icon nc-single-02",
    component: formulario_articulo,
    layout: "/app",
    exact: true
  },
  /******************************************/

  /*Modulo de Proveedores*/
  {
    path: "/proveedores",
    name: "Proveedores",
    icon: "nc-icon nc-app",
    component: lista_proveedores,
    layout: "/app",
    exact: true,
  },
  {
    path: "/proveedores/nuevo",
    name: "Proveedor nuevo",
    icon: "nc-icon nc-single-02",
    component: formulario_proveedores,
    layout: "/app",
    exact: true
  },
  {
    path: "/proveedores/editar/:id",
    name: "Editar Proveedor",
    icon: "nc-icon nc-single-02",
    component: formulario_proveedores,
    layout: "/app",
    exact: true
  },
  /******************************************/

  /*Modulo de almacenes*/
  {
    path: "/almacenes",
    name: "Almacenes",
    icon: "nc-icon nc-app",
    component: lista_almacenes,
    layout: "/app",
    exact: true,
  },
  {
    path: "/almacenes/nuevo",
    name: "Alamcen nuevo",
    icon: "nc-icon nc-app",
    component: formulario_almacenes,
    layout: "/app",
    exact: true
  },
  {
    path: "/almacenes/editar/:id",
    name: "Editar Almacen",
    icon: "nc-icon nc-app",
    component: formulario_almacenes,
    layout: "/app",
    exact: true
  },
  /******************************************/
/*Modulo de compras*/
  {
    path: "/compras",
    name: "compras",
    icon: "nc-icon nc-app",
    component: lista_compras,
    layout: "/app",
    exact: true,
  },
  {
    path: "/compras/nuevo",
    name: "Nueva Compra",
    icon: "nc-icon nc-app",
    component: formulario_compras,
    layout: "/app",
    exact: true
  },
  {
    path: "/compras/editar/:id",
    name: "Editar Compra",
    icon: "nc-icon nc-app",
    component: formulario_compras,
    layout: "/app",
    exact: true
  },
  /******************************************/

  /*Modulo de compras*/
  {
    path: "/mov/almacenes",
    name: "Movimientos Almacen",
    icon: "nc-icon nc-app",
    component: lista_movalmacen,
    layout: "/app",
    exact: true,
  },
  {
    path: "/mov/almacenes/nuevo",
    name: "Nuevo Movimiento Almacen",
    icon: "nc-icon nc-app",
    component: formulario_movalmacen,
    layout: "/app",
    exact: true
  },
  {
    path: "/mov/almacenes/editar/:id",
    name: "Editar Movimiento Almacen",
    icon: "nc-icon nc-app",
    component: formulario_movalmacen,
    layout: "/app",
    exact: true
  },
  /******************************************/

  /*Modulo de Clientes*/
  {
    path: "/clientes",
    name: "Clientes",
    icon: "nc-icon nc-app",
    component: lista_clientes,
    layout: "/app",
    exact: true,
  },
  {
    path: "/clientes/nuevo",
    name: "Nuevo Cliente",
    icon: "nc-icon nc-app",
    component: formulario_clientes,
    layout: "/app",
    exact: true
  },
  {
    path: "/clientes/editar/:id",
    name: "Editar Cliente",
    icon: "nc-icon nc-app",
    component: formulario_clientes,
    layout: "/app",
    exact: true
  },
  /******************************************/


  /*Modulo de Proveedores*/
  {
    path: "/a_cliente",
    name: "A Clientes",
    icon: "nc-icon nc-app",
    component: lista_ca_clientes,
    layout: "/app",
    exact: true,
  },
  {
    path: "/a_clientes/nuevo",
    name: "Nuevo A Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_abono_clientes,
    layout: "/app",
    exact: true
  },
  {
    path: "/a_clientes/editar/:id",
    name: "Editar A Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_abono_clientes,
    layout: "/app",
    exact: true
  },
  {
    path: "/c_clientes/editar/:id",
    name: "Editar C Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_cargo_clientes,
    layout: "/app",
    exact: true
  },

  {
    path: "/c_clientes/nuevo",
    name: "Nuevo A Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_cargo_clientes,
    layout: "/app",
    exact: true
  },

  {
    path: "/an_clientes/editar/:id",
    name: "Editar Anticipo Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_anticipo_clientes,
    layout: "/app",
    exact: true
  },

  {
    path: "/an_clientes/nuevo",
    name: "Nuevo Anticipo Cliente",
    icon: "nc-icon nc-single-02",
    component: formulario_anticipo_clientes,
    layout: "/app",
    exact: true
  },
  /******************************************/
  /*Modulo de C/A Proveedores*/
  {
    path: "/a_proveedores",
    name: "A Proveedores",
    icon: "nc-icon nc-app",
    component: lista_ca_proveedores,
    layout: "/app",
    exact: true,
  },
  {
    path: "/a_proveedores/nuevo",
    name: "Nuevo A Proveedor",
    icon: "nc-icon nc-app",
    component: formulario_ca_proveedores,
    layout: "/app",
    exact: true
  },
  {
    path: "/a_proveedores/editar/:id",
    name: "Editar A Proveedor",
    icon: "nc-icon nc-app",
    component: formulario_ca_proveedores,
    layout: "/app",
    exact: true
  },
  /******************************************/
/*Modulo de Facturas*/
  {
    path: "/facturas",
    name: "Facturas",
    icon: "nc-icon nc-app",
    component: lista_facturas,
    layout: "/app",
    exact: true,
  },
  {
    path: "/facturas/nuevo",
    name: "Nuevo Cliente",
    icon: "nc-icon nc-app",
    component: formulario_facturas,
    layout: "/app",
    exact: true
  },
  {
    path: "/facturas/editar/:id",
    name: "Editar Factura",
    icon: "nc-icon nc-app",
    component: formulario_facturas,
    layout: "/app",
    exact: true
  },
  {
    path: "/facturas_imp/editar/:id",
    name: "Editar Factura",
    icon: "nc-icon nc-app",
    component: importar_facturas,
    layout: "/app",
    exact: true
  },
  {
    path: "/facturas_imp",
    name: "Importar Factura",
    icon: "nc-icon nc-app",
    component: importar_facturas,
    layout: "/app",
    exact: true
  },
  /******************************************/
 /*Modulo de Rendimiento*/
  {
    path: "/mov/rendimiento",
    name: "Movimientos Rendimiento",
    icon: "nc-icon nc-app",
    component: lista_rendimiento,
    layout: "/app",
    exact: true,
  },
  {
    path: "/mov/rendimiento/nuevo",
    name: "Nuevo Rendimiento",
    icon: "nc-icon nc-app",
    component: formulario_rendimiento,
    layout: "/app",
    exact: true
  },
  {
    path: "/mov/rendimiento/editar/:id",
    name: "Editar Rendimiento",
    icon: "nc-icon nc-app",
    component: formulario_rendimiento,
    layout: "/app",
    exact: true
  },


  /*Modulo de Rendimiento*/
  {
    path: "/recetas",
    name: " Recetas",
    icon: "nc-icon nc-app",
    component: lista_recetas,
    layout: "/app",
    exact: true,
  },
  {
    path: "/recetas/nuevo",
    name: "Nuevo Receta",
    icon: "nc-icon nc-app",
    component: formulario_receta,
    layout: "/app",
    exact: true
  },
  {
    path: "/recetas/editar/:id",
    name: "Editar Receta",
    icon: "nc-icon nc-app",
    component: formulario_receta,
    layout: "/app",
    exact: true
  },

  /*Modulo de inventario Fisico*/
  {
    path: "/mov/invfisico",
    name: "Inv Fisico",
    icon: "nc-icon nc-app",
    component: lista_inv_fisico,
    layout: "/app",
    exact: true,
  },
  {
    path: "/mov/invfisico/nuevo",
    name: "Inv Fisico nuevo",
    icon: "nc-icon nc-app",
    component: formulario_inv_fisico,
    layout: "/app",
    exact: true
  },
  {
    path: "/mov/invfisico/editar/:id",
    name: "Editar Inv Fisico",
    icon: "nc-icon nc-app",
    component: formulario_inv_fisico,
    layout: "/app",
    exact: true
  },
  /******************************************/





































  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-diamond",
    component: Icons,
    layout: "/app"
  },
];



export default routes;
