import { api } from './_request';


 export const get = (data) => {
    return (dispatch) => {
                dispatch({
                type: 'LIST',
                    payload: data
                });
    }
 };

export const saveupdate = (data, callback) => {
    return (dispatch) => {

        dispatch({ type: 'SAVE' });

        api().post('/saveupdate/inventario_fisico', data).then(function(response) {

            if(response.status === 200) {

                dispatch({
                    type: 'SAVE_SUCCESS',
                    payload: response.data
                });

                if(typeof callback === 'function') callback(response);

            }
            else{
                dispatch({ type: 'SAVE_FAILURE' });
            }

        })
        .catch(function(error) {
            dispatch({ type: 'SAVE_FAILURE' });
        });

    }
};