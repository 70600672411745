import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/rendimiento';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalProductos from './ModalProductos';
import ModalProductosAlmacen from './ModalProductosAlmacen';
import ModalProductosDocumento from './ModalProductosDocumento';
import Select from 'react-select';

const niveles = [
    {label: 'Entrada', value: 1},
    {label: 'Salida', value: 2},
    {label: 'Transferencia', value: 3},
];

class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        'fecha':'',
        'referencia':'',
        'tipo':1,
        'concepto':'',
        'origen_id':0,
        'destino_id':0,
        'documento_id':0,
        insumo_id:0,
      },
      productos:[],
      action : 'save',
      submit:false,
      activeTab: '2',
      modal:false,
      tabla_modal:0,
      titulo:'',
      campo_modal:'',
      modal_productos:false,
      tipo:{label: 'Entrada', value: 1},
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
     this.toggle_modal= this.toggle_modal.bind(this); 
     this.retorno= this.retorno.bind(this);   
     this.ChangeCode= this.ChangeCode.bind(this);  
     this.handleClose= this.handleClose.bind(this);
     this.toggle_tab= this.toggle_tab.bind(this);
     this.toogle_productos= this.toogle_productos.bind(this);
     this.retornoProducto = this.retornoProducto.bind(this);
     this.delete = this.delete.bind(this);

     this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)
        let self=this;

          swal.queue([{
        title: 'Al Cambiar De tipo ',
        text: " Se Limpiara la informacion general y los productos",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            self.setState({
                [name]: select,
                productos:[],
                codigo_origen_id:'',
                codigo_destino_id:'',
                codigo_documento_id:'',
                label_origen_id:'',
                label_destino_id:'',
                label_documento_id:'',
                articulo: {
                    ...self.state.articulo,
                    [name]: value,
                    'origen_id':0,
                    'destino_id':0,
                    'documento_id':0,
                }
            });
        }
      }])
    }
  delete(evt,index){
      let {productos} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar El producto: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                productos.splice(index,1);
        self.setState({
          
           productos:productos,
          });
        }
      }])


         
    }

  retornoProducto(obj){
       let {productos,articulo} = this.state;
       var indiceproducto= this.state.indiceproducto;
       console.log('retorno producto---->',obj);
       let bandera=true;
       // console.log('indice--->',indiceproducto)
       obj.unitario=( articulo.unitario_recuperable*(parseFloat(obj.peso_total)/parseFloat(obj.cantidad)) ).toFixed(4);
       obj.unidad=obj.unidad_analisis;
       obj.peso_unidad=( (parseFloat(obj.peso_total)/parseFloat(obj.cantidad)) ).toFixed(4);
       obj.total=(parseFloat(obj.unitario)*parseFloat(obj.cantidad)).toFixed(2);
       if(indiceproducto==null){
        for (var i = productos.length - 1; i >= 0; i--) {
        if(obj.articulo_id==productos[i].articulo_id)
            {
             bandera=false;
             swal.fire("Oops..", "Producto ya ingresado");
             break;
            }
       }


       if(bandera)
        productos.push(obj);
      else
        return


       }else{
        productos[indiceproducto]=obj;
       }

       this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : null,  
            productos:productos,
        })


  }
  toogle_productos(evt,indice=null){  
    let {articulo}=this.state;
    console.log('recuperable--->',parseInt(articulo.total_recuperable))
    if(parseInt(articulo.total_recuperable)==0||isNaN(parseInt(articulo.total_recuperable)) ){
      swal.fire("Oops..", "Favor de llenar el Campo de Recuperable");
      return;
    }


    
        this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : indice,  
        });
    
        
  }
  toggle_tab(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
  }
  toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  
        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
  } 
  retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let {articulo} = this.state;
        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;
          
        }else{
          if(campo=='documento_id'){
            id=0;
          }
        }



        if(campo=='insumo_id'){
          console.log('insumo--->',objeto)
            self.setState({
                  articulo: {
                      ...this.state.articulo,
                      [campo]: id,
                      unidad:objeto.unidad_analisis,
                      unitario:objeto.precio6,
                      unitario_recuperable:objeto.precio6,

                  },         
                  ['label_'+campo]:objeto.descripcion,
                  ['codigo_'+campo]:codigo,

                  modal:false,         
              })
          }else{
            self.setState({
                  articulo: {
                      ...this.state.articulo,
                      [campo]: id
                  },         
                  ['label_'+campo]:label,
                  ['codigo_'+campo]:codigo,
                  modal:false,         
              })
          }



        
  }
  ChangeCode(event) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      // console.log('name---->',name);
      // console.log('value---->',value);
      this.setState({
          [name] : value
      });
  }
  handleClose (event,tabla,campo) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      let self =this;
      if(value!=""){
          api().get(`${tabla}/${value}`)
          .then(function(response)
          {                  
              if(response.status === 200)
              { 
                  let vaciar=0;
                   if(Object.keys(response.data).length > 0){
                     
                     
                   }  else{
                       vaciar=1
                       swal.fire("Oops..", "No Existe el Codigo");
                   }
                   self.retorno(response.data,campo,vaciar,value);        
                 
              }

          });
      }else{
          self.retorno([],campo,1,value); 
      }
  }
  componentDidMount(){
    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;

    if(params.id) {
      api().get("/getrendimiento/"+params.id)
      .then(function(response){
        if(response.status === 200){
          articulo = response.data.compra;
          _self.setState({
            articulo : response.data.compra,
            productos : response.data.productos,
            codigo_documento_id:response.data.compra.codigo_documento_id,
            label_documento_id:response.data.compra.label_documento_id,
            codigo_origen_id:response.data.compra.codigo_origen_id,
            label_origen_id:response.data.compra.label_origen_id,
            codigo_destino_id:response.data.compra.codigo_destino_id,
            label_destino_id:response.data.compra.label_destino_id,
            codigo_insumo_id:response.data.compra.codigo_insumo_id,
            label_insumo_id:response.data.compra.label_insumo_id,
            action : 'update'
          })
        }

      });
    }




  }
  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
    // console.log('articulo handeInputChange---->',articulo)
    if(name=='peso'){
      articulo.total_insumo=(parseFloat(articulo.peso)*parseFloat(articulo.unitario)).toFixed(2);
    }

    if(name=='recuperable'){
      /*sacamos el porcentaje */
      
      articulo.merma=(parseFloat(articulo.peso)-parseFloat(articulo.recuperable)).toFixed(4);
      articulo.porcentaje_merma= ( parseFloat(articulo.merma)*100/parseFloat(articulo.peso)).toFixed(2);
      articulo.unitario_recuperable=(  parseFloat(articulo.total_insumo)/parseFloat(articulo.recuperable)).toFixed(4);
      articulo.total_recuperable=(parseFloat(articulo.recuperable)*parseFloat(articulo.unitario_recuperable)).toFixed(2);
    }
    this.setState({
        articulo : articulo
    });
  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,action,productos} = this.state;
    let save = true;
    // _self.setState({
    //         submit : true
    //       })
    articulo.productos=productos;
    console.log('compra------->',articulo)
    let bandera_pedimento=false;
      if(productos.length == 0)
        {
         
        swal.fire("Alerta!", 'Favor de Agregar Productos', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
      }
//console.log('validacion de proveedor')

    if(parseInt(articulo.origen_id)==0){
     
        swal.fire("Alerta!", 'Asigne un almacen de Origen', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
    }
    if(parseInt(articulo.destino_id)==0){
     
        swal.fire("Alerta!", 'Asigne un almacen de Destino', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
    }
    let total_cantidad_productos=0;
    for (var i = productos.length - 1; i >= 0; i--) {
      total_cantidad_productos+=parseFloat(productos[i].peso_total);
    }

    console.log('total_cantidad_productos-->',total_cantidad_productos);
    console.log('articulo.recuperable-->',articulo.recuperable);


    if(!(total_cantidad_productos==parseFloat(articulo.recuperable)))
    {
      swal.fire("Alerta!", 'Favor de Revisar las Cantidades de los insumos tiene que ser igual al Peso del insumo Principal ', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
    }

    this.props.saveupdate(articulo, (response) => {
                if(response.status){
                  if(response.data.estado){
                    //console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/mov/rendimiento');
                        });
                    
                     _self.setState({
                      articulo : response.data.data,
                      action : 'update',
                      submit : false
                    })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  //console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });
  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/mov/rendimiento');
  }

  render() {
     let {submit,articulo} = this.state;
     console.log('state---->',this.state)
    return (
        <div className="content ">
          <Row>
            <Col md="12">
            <Form onSubmit={this.handleSubmit}>
              <Card className="card-user">
                <CardHeader>
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                  <div className='row'>
                  <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Almacen Origen </label>
                                  <input type="text" className="form-control" 
                                  name="label_origen_id"
                                  required
                                  value={this.state.label_origen_id}
                                  onChange={this.ChangeCode} 
                                  readOnly
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos ajuste_catalgo"
                                  color="secondary"

                                  onClick={ (button) => { this.toggle_modal(16,"Almacenes","origen_id") } }>
                                  +
                                </Button>
                            </Col>
                          
                           
                         
                          
                            <Col md="2" className='mt-md-1'>
                              <div className="form-group row">
                                <div className="col-12">
                                <label  className=" col-form-label">Almacen Destino </label>
                                  <input type="text" className="form-control" 
                                    name="codigo_destino_id"
                                    required
                                    value={this.state.label_destino_id}
                                   
                                    readOnly
                                   
                                   />
                                </div>
                              </div>
                            </Col>
                           
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos ajuste_catalgo"
                                  color="secondary"
                                  onClick={ (button) => { this.toggle_modal(16,"Almacenes","destino_id") } }>
                                  +
                                </Button>
                            </Col>
                  <div className="form-group col-12 col-md-4 col-lg-3">
                    <label  className=" col-form-label">Fecha : </label>
                      <input type="date" className="form-control" 
                        name="fecha"
                        required
                        value={articulo.fecha}
                        onChange={this.handeInputChange}
                       />
                  </div>


                  </div>


                   <div className='row'>
                          <Col md="12">
                          
                          <Row>

                           <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Insumo </label>
                                  <input type="text" className="form-control" 
                                  name="codigo_insumo_id"
                                  required
                                  value={this.state.codigo_insumo_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getarticulo/codigo","insumo_id")
                                      }
                                    }}
                               
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos ajuste_catalgo"
                                  color="secondary"
                                
                                  onClick={ (button) => { this.toggle_modal(17,"Insumos","insumo_id") } }>
                                  +
                                </Button>
                            </Col>
                            <Col sm='12' md="5">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Descripción </label>
                                  <input type="text" className="form-control" 
                                  name="label_insumo_id"
                                  required
                                  readOnly
                                  value={this.state.label_insumo_id}
                                   onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Peso</label>
                                  <input type="number" step='0.01' className="form-control" 
                                  name="peso"
                                  required
                                  readOnly={(parseInt(articulo.insumo_id)==0||this.state.productos.length>0)?true:false}
                                  value={articulo.peso}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Unidad </label>
                                  <input type="text" className="form-control" 
                                  name="unidad"
                                  required
                                  readOnly
                                   value={articulo.unidad}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Unitario </label>
                                  <input type="text" className="form-control" 
                                  name="unitario"
                                  required
                                  readOnly
                                 value={articulo.unitario}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                               <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Total </label>
                                  <input type="text" className="form-control" 
                                  name="total_insumo"
                                  required
                                  readOnly
                                   value={articulo.total_insumo}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                           



                           
                            
                            
                           
                            
                            
                          </Row>
                          </Col>
              

                        </div>
                        <Row className='justify-content-end'>
                        <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">% </label>
                                  <input type="text" className="form-control" 
                                  name="porcentaje_merma"
                                  required
                                  readOnly
                                   value={articulo.porcentaje_merma}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Recuperable </label>
                                  <input type="text" className="form-control" 
                                  name="recuperable"
                                  required
                                  readOnly={(parseInt(articulo.insumo_id)==0||this.state.productos.length>0)?true:false}
                                   value={articulo.recuperable}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Merma </label>
                                  <input type="text" className="form-control" 
                                  name="merma"
                                  required
                                  readOnly
                                   value={articulo.merma}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">unitario </label>
                                  <input type="text" className="form-control" 
                                  name="unitario_recuperable"
                                  required
                                  readOnly
                                   value={articulo.unitario_recuperable}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>
                          <Col sm='1' md="1">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Total </label>
                                  <input type="text" className="form-control" 
                                  name="total_recuperable"
                                  required
                                  readOnly
                                   value={articulo.total_recuperable}
                                  onChange={this.handeInputChange}
          
                                   />
                                </div>
                              </div>
                            </Col>

                        </Row>







                  <Row className='pl-3 pr-3'>
                    <Nav className='col-12' tabs>
                      
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle_tab('2'); }}
                        >
                          Insumos
                        </NavLink>
                      </NavItem>

                    </Nav>
                    </Row>
                    <Row className='pl-3 pr-3'>
                     <TabContent className='col-12' activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        
                      </TabPane>
                      <TabPane tabId="2">
                      <Row>
                      <Col className="update my-3  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          onClick={(evt)=>{evt.preventDefault(); this.toogle_productos(evt,null); }}
                        >
                          Agregar
                        </Button>
                      </Col>
                    </Row>
                       <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                  if(rowInfo === undefined){
                                          return{ }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                  e.preventDefault(); this.toogle_productos(e,rowInfo.index);
                                            }
                                        } 
                                    }
                              }}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:300,minWidth:300,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                 <Button
                                                 color="danger" 
                                                 className=' btn-sm mr-1  '
                                                 
                                                 onClick={(evt)=>this.delete(evt, row.index)}>
                                                    Eliminar
                                                </Button> 

                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(e)=>{e.preventDefault(); this.toogle_productos(e,row.index); }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Codigo', accessor: 'codigo',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Descripción', accessor: 'descripcion'},
                                {Header: 'Cantidad', accessor: 'cantidad',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Unidad', accessor: 'unidad',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Peso Total', accessor: 'peso_total',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Peso/Unidad', accessor: 'peso_unidad',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                
                                {Header: 'Unitario', accessor: 'unitario',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Total', accessor: 'total',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},

                                ]}
                              data      = {this.state.productos} 
                              filterable
                              defaultPageSize={5}
                            />
                      </TabPane>
                      </TabContent>
                  </Row>
                </Col>
                </Row>
                </CardBody>
              </Card>
              </Form>
            </Col>
          </Row>

          {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}
                  
              />
          }

          {/*modal para la entrada sin documento de compra*/
           (this.state.modal_productos )&&
                <ModalProductos
                    open={this.state.modal_productos}
                    toggle={this.toogle_productos}  
                    renglon={this.state.indiceproducto!=null?this.state.productos[this.state.indiceproducto]:[]} 
                    retorno={this.retornoProducto}
                    indice={this.state.indiceproducto}
                />
            }

            
            

        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        compra:state.compra
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

