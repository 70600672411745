import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";
import Spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';
import ModalTablas from '../catalogos/modal_tablas';

import ReactTable from "react-table";
import "react-table/react-table.css";

const tipos = [
    {label: 'Insumos', value: 1},
    {label: 'Recetas', value: 2},
];
const niveles = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
];


const requestData = (pageSize, page, sorted, filtered,tipo,nivel) => {    
    return new Promise((resolve, reject) => {
        // You can retrieve your data however you want, in this case, we will just use some local data.
        let filteredData ;
       
        var nombre='';
        var id='';
        var take= pageSize;
        var skip=pageSize * page;
        var total=1; 
        
        for (var i = filtered.length - 1; i >= 0; i--) {
          switch(filtered[i].id)
          {
            case "nombre":
              nombre=filtered[i].value;
              console.log('nombre--->',filtered[i].value);
              break;
            case "id":
              id=filtered[i].value;
              console.log('id--->',filtered[i].value);
              break; 
          }
        }
              
              api().get(`/getmodificadores?nombre=${nombre}&id=${id}&take=${take}&skip=${skip}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                  filteredData= response.data.datos;
                  total= response.data.total; 
                  // You can also use the sorting in your request, but again, you are responsible for applying it.
                  const sortedData = _.orderBy(
                    filteredData,
                    sorted.map(sort => {
                      return row => {
                        if (row[sort.id] === null || row[sort.id] === undefined) {
                          return -Infinity;
                        }
                        return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                      };
                    }),
                    sorted.map(d => (d.desc ? "desc" : "asc"))
                  );
                  const res = {
                    rows: sortedData,
                    pages: Math.ceil(total / pageSize)
                  };
                  // Here we'll simulate a server response with 500ms of delay.
                  //setTimeout(() => resolve(res), 1000);
                  //console.log(res);
                  resolve(res)
                }
              });   
        
    });
};


class ModalCategorias extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
            tipo:{label: 'Insumos', value: 1},
            nivel:{label: '1', value: 1},
            action:'save',
            categoria:{
                tipo:1,
                nivel:1,
                nombre:'',
                id:'',
                cantidad_insumo:1,
            },
            submit:false,
            pages: null,
            loading: true,
		
        };
        this.handleSelectChange= this.handleSelectChange.bind(this);
        this.regresar= this.regresar.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.editar= this.editar.bind(this);
        this.handeInputChange= this.handeInputChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.table = React.createRef();
	}

    handleClose =(event,tabla,campo)=> {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }
  ChangeCode=(event)=> {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        // console.log('name---->',name);
        // console.log('value---->',value);
        this.setState({
           categoria: {
                ...this.state.categoria,
                [name]: value,
               
               
            }, 
        });
    }

  retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let unidad="";
          let costo=this.state.categoria.precio;
        let pedimento=0;
        let serie=0;
        let {articulo} = this.state;
        let self =this;
        // console.log('retorno---->',objeto)
        if(vacio==0){
          label=objeto.descripcion;
          codigo=objeto.codigo;
          id=objeto.id;
          pedimento=objeto.pedimento;
          serie=objeto.serie;
          unidad=objeto.unidad_analisis;
          costo=parseFloat(objeto.precio6).toFixed(2);
        }

        self.setState({
            categoria: {
                ...this.state.categoria,
                [campo]: id,
                insumo:label,
                codigo_insumo_id:codigo,
                precio:(parseFloat(this.state.categoria.cantidad_insumo)*costo).toFixed(2),
                unidad:unidad,
                costo_suge:(parseFloat(this.state.categoria.cantidad_insumo)*costo).toFixed(2),
                costo_unitario:costo,
               
            },         
           
            modal:false,         
        })
    }

    toggle_modal=(index=0,catalogo="",campo="",tipo=0,nivel=0)=>{  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
    }

    handeInputChange(event){
    let {categoria} = this.state;

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;



    categoria[name] = value;

     if (name=='cantidad_insumo') {
        categoria.costo_suge=(parseFloat(categoria.cantidad_insumo)*parseFloat(categoria.costo_unitario)).toFixed(2)

    }
// console.log('categoria handeInputChange---->',categoria)
    this.setState({
        categoria : categoria
    });
  }


     fetchData(state, instance) {
    // Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
    // You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
    this.setState({ loading: true });
    // Request the data however you want.  Here, we'll use our mocked service we created earlier
    requestData(
      state.pageSize,
      state.page,
      state.sorted,
      state.filtered,
      this.state.tipo.value,
      this.state.nivel.value,
    ).then(res => {
      // Now just get the rows of data to your React Table (and update anything else like total pages or loading)
      this.setState({
        data: res.rows,
        pages: res.pages,
        loading: false
      });
      // console.log('datos--->',res.rows);
       // this.props.get(res.rows);
    });
  }

    regresar() {
             this.setState({
                action:'save',
                categoria: {
                    ...this.state.categoria,
                     nombre:'',
                     id:'',
                     insumo_id:'',
                     insumo:'',
                     cantidad_insumo:1,
                     codigo_insumo_id:'',
                     costo_suge:'',
                     unidad:'',
                     precio:''
                }
            });
        
    }

    editar(row){


        row.costo_unitario=parseFloat(row.costo_unitario).toFixed(2)
        row.costo_suge=(parseFloat(row.cantidad_insumo)*parseFloat(row.costo_unitario)).toFixed(2)
        console.log('Row---->',row)
        this.setState({
                action:'update',
                categoria: Object.assign({}, row),
            });

    }


    eliminar=(row)=>{
        let _self=this;

        swal.queue([{
        title: 'Timbrar Factura ',
        text: "Estas seguro ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return api().post(`/delete/modificadores/${row.id}`, []).then(function(response) {

            if(response.status){
                  if(response.data.estado){

                    // console.log('actualizar')
                    swal.insertQueueStep( {
                        type: 'success',
                        title:  "Se Elimino con éxito!"});
                    
                     _self.setState({
                      categoria: {
                            ..._self.state.categoria,
                            nombre:'',
                             id:'',
                             insumo_id:'',
                             insumo:'',
                             cantidad_insumo:1,
                             codigo_insumo_id:'',
                             costo_suge:'',
                             unidad:'',
                             precio:''
                        },
                      action : 'save',
                      submit : false
                    }, () => {
                           _self.fetchData(_self.table.current.state)
                        });
                  }else{
                    swal.insertQueueStep({
                        type: 'error',
                        title: response.data.mensaje});
                    _self.setState({
                        submit : false
                      });
                  }
                }

        })
        .catch(function(error) {
            console.log('error',error)
           swal.insertQueueStep({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
        });






        }
      }])
       
  

    }


    handleSelectChange(select, name) {

        
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)

             this.setState({
                [name]: select,
                categoria: {
                    ...this.state.categoria,
                    [name]: value
                }
            }, () => {
           this.fetchData(this.table.current.state)
        });
        
    }

     handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {categoria,action} = this.state;
    let save = true;
    _self.setState({
            submit : true
          })


    api().post('/saveupdate/modificadores', categoria).then(function(response) {

            if(response.status){
                  if(response.data.estado){
                    // console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success");
                    
                     _self.setState({
                      categoria: {
                            ..._self.state.categoria,
                              nombre:'',
                             id:'',
                             insumo_id:'',
                             insumo:'',
                             cantidad_insumo:1,
                             codigo_insumo_id:'',
                             costo_suge:'',
                             unidad:'',
                             precio:''
                        },
                      action : 'save',
                      submit : false
                    }, () => {
                           _self.fetchData(_self.table.current.state)
                        });
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }

        })
        .catch(function(error) {
            console.log('error',error)
           swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
        });

 



  }


	render()
	{

     
        let {submit,categoria,pages, loading}= this.state;
        console.log('state modal---->',this.state)
		
		return(
            /*charCode   es para poner texto en el boton para cerrar  pero aqui se uso para eliminarlo*/
			<Modal isOpen={this.props.open} toggle={this.props.toggle} charCode="X" className="default modal-lg ">				
                <ModalHeader >Modificadores</ModalHeader>
                <ModalBody> 
                <div className="content">
                <Row>
                
               
                </Row>
                <Row>
                <div className="col-md-1"> 
                    <div className="form-group">
                        <label >Codigo *:</label>
                        <input type="text" className="form-control" 
                        name="nombre"
                        
                        readOnly 
                        value={categoria.id}
                        />
                    </div>
                </div>
                <div className="col-md-5"> 
                    <div className="form-group">
                        <label >Descripción *:</label>
                        <input type="text" className="form-control" 
                        name="nombre"
                        required
                        value={categoria.nombre}
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Costo *:</label>
                        <input type="number" step className="form-control" 
                        name="precio"
                        required
                        value={categoria.precio}
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Costo sugerido *:</label>
                        <input type="number" step className="form-control" 
                        name="costo_suge"
                        required
                        readOnly 
                        value={categoria.costo_suge}
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                <div className="col-md-2"> 
                  
                </div>
                <Col  md="2">
                              <div className="form-group ">
                             
                             
                                <label  className=" ">Codigo * : </label>
                                  <input type="text" className="form-control" 
                                  name="codigo_insumo_id"
                                  required
                                  value={categoria.codigo_insumo_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,"/getarticulo/codigo","articulo_id")
                                      }
                                    }}
                                   />
                                </div>
                             
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos he"
                                  color="secondary"
                                  style={{bottom: '18px', position: 'absolute'}}
                                  onClick={ (button) => { this.toggle_modal(17,"Articulos","articulo_id") } }>
                                  +
                                </Button>
                            </Col>
               
                <div className="col-md-5"> 
                    <div className="form-group">
                        <label >Insumo * {categoria.insumo}:</label>
                        <input type="text" className="form-control" 
                        name="insumo"
                        value={categoria.insumo?categoria.insumo:''}
                        readOnly 
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                <div className="col-md-2"> 
                    <div className="form-group">
                        <label >Cantidad *:</label>
                        <input type="number" step className="form-control" 
                        name="cantidad_insumo"
                        required
                        value={categoria.cantidad_insumo}
                        onChange={this.handeInputChange} />
                    
                    </div>
                </div>
                 <div className="col-md-4"> 
                    <div className="form-group pt-3">
                     <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                          onClick={this.handleSubmit}>
                          Guardar
                        </Button>
                        {this.state.action =='update'&&

                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                         }
                        
                    
                    </div>
                </div>
                </Row>
                <Row>
                <div className='col-12'>
                <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                 
                                  if(rowInfo === undefined){
                                         
                                          return{

                                          }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                    this.editar(rowInfo.original); 
                                            }
                                        } 
                                  
                                    }
                              }}
                              
                              
                              className = "-striped -highlight"
                              columns   = {[
                                {Header: '#',filterable: false, accessor: 'id',maxWidth:100,minWidth:100,style: { textAlign: 'center'}},
                                {Header: 'Nombre', accessor: 'nombre'},
                               
                               
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                     maxWidth:250,minWidth:150,
                                    Cell: (row) =>
                                    {
                                        return(
                                            <div className="text-center">
                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(evt)=>{this.editar(row.original);  }}>
                                                    Editar
                                                </Button>
                                                <Button
                                                 color="danger" 
                                                 className="btn-sm" onClick={(evt)=>{this.eliminar(row.original);  }}>
                                                    Eliminar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                }
                              ]}
                              manual // Forces table not to paginate or sort automatically, so we can handle it server-side
                              data      = {this.state.data}
                              pages={pages} // Display the total number of pages
                              loading={loading} // Display the loading overlay when we need it
                              onFetchData={_.debounce(this.fetchData, 500)} // Request new data when things change
                              filterable
                              ref={this.table}
                              defaultPageSize={5} 
                            />
                    
                </div>
                </Row>
                </div>    
                </ModalBody>
               <ModalFooter>
                   
                       
                        <div>                            
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cerrar
                            </Button>
                        </div>
                    
                </ModalFooter>    
                {
                this.state.modal&&
                <ModalTablas
                      open={this.state.modal}
                      toggle={this.toggle_modal}                  
                      titulo={this.state.titulo}
                      catalogo={this.state.tabla_modal}
                      retorno={this.retorno}
                      campo={this.state.campo_modal}
                      nivel={this.state.nivel_modal}
                      tipo={this.state.tipo_modal}
                      
                  />
              }           
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalCategorias)