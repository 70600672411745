import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon,Col,Row,Form,UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import swal from 'sweetalert2';
import * as actions from '../../actions/auth';
import {api} from '../../actions/_request';

import ReactTable from "react-table";
import "react-table/react-table.css";
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';



const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  if (row[id] !== null){
    return (row[id] !== undefined ? String((row[id]+'').toLowerCase()).includes(filter.value.toLowerCase()) : true );
  }
};





class TicketModal extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
              articulo:{
                    articulo_id:0,
                    cantidad:1,
                    costo:'',
                    pedimento:0,
                    serie:0,
                    series:[],
                    ieps:'',
                    cuota_fija_ieps:0,
                    cuota_fija_otro:0,
                    impuesto_otro:'',
                    cantidad_fiscal:0,
                    iva:'',
                    retencion_isr:'',
                    retencion_iva:'',
                    excento_iva:0,
                    descuento:0,
                    salida:0,
                    sin_existencia:0,                   
              },
              info:{
                precio1:0,
                precio2:0,
                precio3:0,
                precio4:0,
                precio5:0,
                precio6:0,

              },
              input_serie:'',
              indice_serie:null,
              action:'save',
              submit:false,
        };  
        this.handeInputChange = this.handeInputChange.bind(this);
        this.handleInputChangestate = this.handleInputChangestate.bind(this);
        this.toggle_modal = this.toggle_modal.bind(this);
        this.retorno = this.retorno.bind(this);
        this.ChangeCode = this.ChangeCode.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
	}


  delete=(evt,index)=>{
      let {articulo} = this.state;   
      let submit=true;
      /*peticion para agregar*/
      let self = this;

      swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar La serie: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          articulo.series.splice(index,1);
          self.setState({
            articulo:articulo,
          });
        }
      }])
    }


     numero_truncado= (x) =>{
      return parseFloat( Number.parseFloat(x).toFixed(2));
      var  posiciones = 2;
      var s = x.toString()
      var l = s.length
      var decimalLength = s.indexOf('.') + 1

      if (l - decimalLength <= posiciones){
        return x
      }
      // Parte decimal del número
      var isNeg  = x < 0
      var decimal =  x % 1
      var entera  = isNeg ? Math.ceil(x) : Math.floor(x)
      // Parte decimal como número entero
      // Ejemplo: parte decimal = 0.77
      // decimalFormated = 0.77 * (10^posiciones)
      // si posiciones es 2 ==> 0.77 * 100
      // si posiciones es 3 ==> 0.77 * 1000
      var decimalFormated = Math.floor(
        Math.abs(decimal) * Math.pow(10, posiciones)
      )
      // Sustraemos del número original la parte decimal
      // y le sumamos la parte decimal que hemos formateado
      var finalNum = entera + 
        ((decimalFormated / Math.pow(10, posiciones))*(isNeg ? -1 : 1))
      
      return finalNum
    }

  push=(objeto)=>{
      let {articulo,input_serie,indice_serie} = this.state;
      let self = this;
      let modal=false;
      /*verificamos que la serie no este en el arreglo*/
      for (var i = articulo.series.length - 1; i >= 0; i--) {
        console.log('serie:',articulo.series[i].serie)
        if(objeto.serie==articulo.series[i].serie){
          modal=true;
        }
      }
      if(modal){
        swal.fire("Oops..", "La serie ya se encuentra en la lista");
      }else{
        articulo.series.push({
          id:null,
          serie:objeto.serie,
        });
        this.setState({
          articulo:articulo,
          input_serie:'',
          indice_serie:null,
          action:'save',
          modal:modal,     
        });
      }
    }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo} = this.state;
    _self.setState({
            submit : true
          })
    //console.log('articulo--->',articulo)
    if(parseInt( articulo.articulo_id)>0 )

    if( (parseInt(articulo.serie)==1&&articulo.series.length>0&&parseInt(articulo.cantidad)==articulo.series.length) ||parseInt(articulo.serie)==0){
      articulo.descripcion=articulo.descripcion;
      articulo.codigo=this.state.codigo_articulo_id;
      /*hacemos los calculos de los impuestos*/
      articulo.importe=parseFloat(articulo.cantidad)*parseFloat(articulo.precio);
      articulo.descuento_monto=this.numero_truncado((parseFloat(articulo.descuento)/100)*articulo.importe);
      articulo.subtotal=articulo.importe-articulo.descuento_monto;
      /*varificamos  que tipo de ieps es (tasa o cuota)
           tasa es porcentaje en base al subtotal
           cuota = (cantidad_fiscal*ieps)*cantidad
      */

      let ieps_calculo=articulo.ieps!=''?articulo.ieps:0;
      if(articulo.cuota_fija_ieps==1){
        articulo.ieps_monto=( parseFloat(articulo.cantidad_fiscal)*parseFloat(ieps_calculo) )*parseFloat(articulo.cantidad);
      }else{
        articulo.ieps_monto=this.numero_truncado((parseFloat(ieps_calculo)/100)*articulo.subtotal);
      }
      articulo.iva_monto=0;
      /*validamos si el articulo esta exento de iva*/
      let iva_calculo=articulo.iva!=''?articulo.iva:0;

      if(articulo.excento_iva==0)
        articulo.iva_monto=this.numero_truncado((parseFloat(iva_calculo)/100)*articulo.subtotal);
      articulo.impuesto_monto=0;
      /*validamos que el impuesto 3 sea cuota o tasa*/
      // if(articulo.cuota_fija_otro==1){
      //   articulo.impuesto_monto=( parseFloat(articulo.cantidad_fiscal)*parseFloat(articulo.impuesto_otro) )*parseFloat(articulo.cantidad);
      // }else{
      //   articulo.impuesto_monto=(parseFloat(articulo.impuesto_otro)/100)*articulo.subtotal;
      // }

      let retencion_isr_calculo=articulo.retencion_isr!=''?articulo.retencion_isr:0;
      let retencion_iva_calculo=articulo.retencion_iva!=''?articulo.retencion_iva:0;
      articulo.retencion_isr_monto=this.numero_truncado((parseFloat(retencion_isr_calculo)/100)*articulo.subtotal);
      articulo.retencion_iva_monto=this.numero_truncado((parseFloat(retencion_iva_calculo)/100)*articulo.subtotal);
      articulo.retenciones=articulo.retencion_isr_monto+articulo.retencion_iva_monto;
      articulo.total=articulo.subtotal+articulo.ieps_monto+articulo.iva_monto+articulo.impuesto_monto-articulo.retenciones;
      console.log('articulo--->',articulo);
      console.log('importe--->',articulo.importe);
      console.log('descuento_monto--->',articulo.descuento_monto);
      console.log('subtotal--->',articulo.subtotal);
      console.log('iva_monto--->',articulo.iva_monto);
      console.log('ieps_monto--->',articulo.ieps_monto);
      console.log('impuesto_monto--->',articulo.impuesto_monto);
      console.log('retencion_iva_monto--->',articulo.retencion_iva_monto);
      console.log('retencion_isr_monto--->',articulo.retencion_isr_monto);
      console.log('total--->',articulo.total);
       this.props.retorno(articulo);
    }else{
      swal.fire("Oops..", "Favor de Verificar las series tienen que ser igual a la cantidad ingresada ");
      _self.setState({
            submit : false
          });
    }


    else{
      swal.fire("Oops..", "Favor de ingresar un articulo ");
      _self.setState({
            submit : false
          });
    }
    
    //console.log('submit');





  }

  handleClose (event,tabla,campo) {

        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        let self =this;
        if(value!=""){
            api().get(`${tabla}/${value}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1;
                          if(campo=='articulo_id'){
                           swal.fire("Oops..", "No Existe el Codigo o no tiene Existencia");
                         }else{
                            swal.fire("Oops..", "No Existe el Codigo");
                         }
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
            

   }
  ChangeCode(event) {
     
        const target    = event.target;
        const value     = target.type === 'checkbox' ? target.checked : target.value;
        const name      = target.name;
        // console.log('name---->',name);
        // console.log('value---->',value);
        this.setState({
            [name] : value
        });
    }

 dosDecimales(n) {
  let t=n.toString();
  let regex=/(\d*.\d{0,2})/;
  return t.match(regex)[0];
}

  retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let pedimento=0;
        let serie=0;
        let {articulo} = this.state;
        let impuestos=this.props.impuestos;
        let self =this;
        console.log('retorno---->',objeto)
        console.log('props--->',this.props)
        if(vacio==0){
          label=objeto.descripcion;
          codigo=objeto.codigo;
          id=objeto.id;
          pedimento=objeto.pedimento;
          serie=objeto.serie;
        }
        if(campo=='articulo_id'){
          
          /*selecionamos el precio que se le asgino  al cliente*/
          console.log('precio al cliente---->',objeto[this.props.cliente.lista_precios])
          console.log('impuesto iva---->',objeto.iva!=null)
           console.log('cliente---->',this.props.cliente)
          /*modificamos la lista de precios dependiendo del tc y de la moneda que este en el documento 
            el cliente especifico que los productos que sean vendido en mxn  
            tendrian en 0 el precio1(precio en dolares) y el precio5(costo dolares)
            tenemos que verificar  si el producto se vende en pesos o en dolares
            si precio1 es = 0 se vende en mxn 
            si precio1 es > 0 se vende en dolares
            nose valida el precio2  que es el que se asigna a los productos que se venden en mxn
             para evitar que el sistema se trunque al jalar un articulo mal capturado

          */

          // let bandera_moneda='MXN';

          // if(parseFloat(objeto.precio1)>0)
            // bandera_moneda='USD';//el producto se vende en dolares


          /*verificamos la moneda del ducumento es igual al del producto*/

          // if(bandera_moneda!=self.props.moneda){
            /*modificamos la lista de precios a como se necesitan*/
            // if(self.props.moneda=='MXN'&& bandera_moneda=='USD'){
            //   objeto.precio1=this.dosDecimales( parseFloat(objeto.precio1)* parseFloat(this.props.tc));
            //   objeto.precio2=this.dosDecimales( parseFloat(objeto.precio2)* parseFloat(this.props.tc));
            //   objeto.precio3=this.dosDecimales( parseFloat(objeto.precio3)* parseFloat(this.props.tc));
            //   objeto.precio4=this.dosDecimales( parseFloat(objeto.precio4)* parseFloat(this.props.tc));
            //   objeto.precio5=this.dosDecimales( parseFloat(objeto.precio5)* parseFloat(this.props.tc));
            //   objeto.precio6=this.dosDecimales( parseFloat(objeto.precio6)* parseFloat(this.props.tc));
            // }
            // if(self.props.moneda=='USD'&& bandera_moneda=='MXN'){
              if(self.props.moneda=='USD'){
              objeto.precio1=this.dosDecimales( parseFloat(objeto.precio1) / parseFloat(this.props.tc));
              objeto.precio2=this.dosDecimales( parseFloat(objeto.precio2) / parseFloat(this.props.tc));
              objeto.precio3=this.dosDecimales( parseFloat(objeto.precio3) / parseFloat(this.props.tc));
              objeto.precio4=this.dosDecimales( parseFloat(objeto.precio4) / parseFloat(this.props.tc));
              objeto.precio5=this.dosDecimales( parseFloat(objeto.precio5) / parseFloat(this.props.tc));
              objeto.precio6=this.dosDecimales( parseFloat(objeto.precio6) / parseFloat(this.props.tc));
            }

          // }







          self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id,
                descripcion:objeto.descripcion,
                pedimento:pedimento,
                serie:serie,
                // costo:bandera_moneda=='MXN'?objeto.precio6:objeto.precio5,
                costo:objeto.precio6,
                precio:objeto[this.props.cliente.lista_precios],
                unidad:objeto.codigo_unidad_sat_id,
                sat:objeto.codigo_sat_id,
                excento_iva:objeto.exento,
                cuota_fija_ieps:objeto.cuota_fija1,
                cuota_fija_otro:objeto.cuota_fija2,
                iva:objeto.iva!=null?objeto.iva:'',
                ieps:objeto.iesps!=null?objeto.iesps:'',
                impuesto_otro:objeto.impuesto3!=null?objeto.impuesto3:'',
                cantidad_fiscal:objeto.cantidad_fiscal,
                retencion_isr:objeto.retencion_isr!=null?objeto.retencion_isr:'',
                retencion_iva:objeto.retencion_iva!=null?objeto.retencion_iva:'',
                sin_existencia:objeto.venta_sin_existencia,
                series:[],
            },
            info:objeto,         
            codigo_unidad_sat_id:objeto.unidad,
            codigo_sat_id:objeto.sat,
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        },()=>{

          /*tenemos que validar que tipo de impuestos vamos a tomar  */
          switch (parseInt(self.props.tipo_impuestos)) {
            case 1:
                console.log(' impuestos almacen')
                /*aqui solo se toma en cuenta el iva si no es exento en el producto*/
                if(parseInt(objeto.exento)==0){
                  self.setState({
                      articulo: {
                          ...self.state.articulo,
                          iva:impuestos.iva,
                      },          
                  });
                }

                break;
            case 2:
               console.log(' impuestos cliente')
               /*aqui se toman en cuenta  el iva , el ieps  retencion de iva e isr*/
               let iva=impuestos.iva,ieps=impuestos.iva,r_iva=impuestos.retencion_iva,r_isr=impuestos.retencion_isr;


                if(parseInt(objeto.exento)==0){
                  self.setState({
                      articulo: {
                          ...self.state.articulo,
                          iva:iva,
                          ieps:ieps,
                          retencion_isr:r_isr,
                          retencion_iva:r_iva,
                      },          
                  });
                }
                break;
            case 3:
               /*impuestos producto estos ya se cargan por default*/
                break;
            

        }
      


     });
          

        }else{
          self.setState({
            articulo: {
                ...self.state.articulo,
                [campo]: id,
                pedimento:pedimento,
                serie:serie,
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
        }

        
    }

    cambio_precio=(precio)=>{
  this.setState({
            articulo: {
                ...this.state.articulo,
             
                precio:precio,
            
            },         
       
  
        })
}

  toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  

        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
    }
 handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleInputChangestate(event){
   
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

   
// console.log('articulo handeInputChange---->',articulo)
    this.setState({
        [name]: value,
    });
  }
    componentDidMount()
    {
        let cliente=this.props.cliente;
        let self=this;

        if(this.props.indice!=null){
          /*hacemos una peticion para extraer la informacion del producto (por ahorita solo los precios)*/
          console.log('articulo--->',this.props.renglon)
           let bandera_moneda='MXN';

          


          /*verificamos la moneda del ducumento es igual al del producto*/


          api().get(`/getarticuloid/${this.props.renglon.articulo_id}`)
            .then(function(response)
            {                  
                if(response.status === 200)
                {   
                  let objeto = response.data.articulo;
                  if(parseFloat(objeto.precio1)>0)
            bandera_moneda='USD';//el producto se vende en dolares
            if(bandera_moneda!=self.props.moneda){
            /*modificamos la lista de precios a como se necesitan*/
            if(self.props.moneda=='MXN'&& bandera_moneda=='USD'){
              objeto.precio1=self.dosDecimales( parseFloat(objeto.precio1)* parseFloat(self.props.tc));
              objeto.precio2=self.dosDecimales( parseFloat(objeto.precio2)* parseFloat(self.props.tc));
              objeto.precio3=self.dosDecimales( parseFloat(objeto.precio3)* parseFloat(self.props.tc));
              objeto.precio4=self.dosDecimales( parseFloat(objeto.precio4)* parseFloat(self.props.tc));
              objeto.precio5=self.dosDecimales( parseFloat(objeto.precio5)* parseFloat(self.props.tc));
              objeto.precio6=self.dosDecimales( parseFloat(objeto.precio6)* parseFloat(self.props.tc));
            }
            if(self.props.moneda=='USD'&& bandera_moneda=='MXN'){
              objeto.precio1=self.dosDecimales( parseFloat(objeto.precio1) / parseFloat(self.props.tc));
              objeto.precio2=self.dosDecimales( parseFloat(objeto.precio2) / parseFloat(self.props.tc));
              objeto.precio3=self.dosDecimales( parseFloat(objeto.precio3) / parseFloat(self.props.tc));
              objeto.precio4=self.dosDecimales( parseFloat(objeto.precio4) / parseFloat(self.props.tc));
              objeto.precio5=self.dosDecimales( parseFloat(objeto.precio5) / parseFloat(self.props.tc));
              objeto.precio6=self.dosDecimales( parseFloat(objeto.precio6) / parseFloat(self.props.tc));
            }

          }
                            
                   console.log('response--->',response.data.articulo);
                   self.setState({
              info:objeto,

          });
                }

            });

          


          this.setState({
              articulo: this.props.renglon,
              codigo_articulo_id:this.props.renglon.codigo,
              label_articulo_id:this.props.renglon.descripcion,
              codigo_sat_id:this.props.renglon.sat,
              codigo_unidad_sat_id:this.props.renglon.unidad,

          });
        }else{
         // this.setState( {
         //        articulo:{
         //              articulo_id:0,
         //              cantidad:'',
         //              costo:'',
         //              pedimento:0,
         //              serie:0,
         //              series:[],
         //        }
         //      });
        }
    }



	render()
	{

     let {articulo}=this.state;

		
		return(

			<Modal isOpen={this.props.open} toggle={this.props.toggle} style={{marginTop: '35px'}} className="default modal-lg">
				
                <ModalHeader toggle={this.props.toggle}>Articulo</ModalHeader>
                <ModalBody>

                <Form onSubmit={this.handleSubmit}>

                    <Row className='justify-content-center '>
                            <div className=" col-8 text-right">  
                      <Button color="primary"  type="submit"
                          disabled={this.state.submit} onClick={this.props.submit}>
                          Guardar
                        </Button>
                      
                        <Button color="secondary" onClick={this.props.toggle}>
                          Cerrar
                        </Button>
                      </div>
                            <Row className=' col-12'>
                            <Col sm='10' md="2">
                              <div className="form-group row">
                             
                                <div className="col-12 ">
                                <label  className=" col-form-label">Articulo * : </label>
                                  <input type="text" className="form-control" 
                                  name="codigo_articulo_id"
                                  required
                                  value={this.state.codigo_articulo_id}
                                  onChange={this.ChangeCode} 
                                  onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleClose(event,`/getarticulofactura/codigo/${this.props.almacen_id}`,"articulo_id")
                                      }
                                    }}
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos he"
                                  color="secondary"
                                  style={{bottom: '18px', position: 'absolute'}}
                                  onClick={ (button) => { this.toggle_modal(25,"Articulos","articulo_id") } }>
                                  +
                                </Button>
                            </Col>
                            <div className="form-group col-12 col-md-8 ">
                                <label  className=" col-form-label">descripcion : </label>
                                  <input type="text" className="form-control" 
                                    name="descripcion"
                                    required
                                    value={articulo.descripcion}
                                    onChange={this.handeInputChange}
                                   />
                            </div>

                            <div className='col-12'>
                              <div className='row'>
                                 <div className='col-3'>
                                  <div className='row'>
                                    <div className="form-group col-12 col-md-12 ">
                                <label  className=" col-form-label">Costos : </label>
                                  <input type="number" className="form-control" 
                                    name="costo"
                                    required
                                    value={articulo.costo}
                                    onChange={this.handeInputChange}
                                   />
                            </div>
                            <div className="form-group col-12 col-md-12 ">
                                <label  className=" col-form-label">Cantidad : </label>
                                  <input type="number" className="form-control" 
                                    name="cantidad"
                                    required
                                    value={articulo.cantidad}
                                    onChange={this.handeInputChange}
                                   />
                            </div>
                            

                            <div className="form-group col-12 col-md-12 ">
                                <label  className=" col-form-label">Codigo Sat : </label>
                                  <input type="text" className="form-control" 
                                    name="sat"
                                    required
                                    disabled
                                    value={articulo.sat}
                                    onChange={this.handeInputChange}
                                   />
                            </div>
                            </div>
                            </div>





                            <div className='col-3'>
                                  <div className='row'>

                                  <div className="col-9 col-md-10"> 
                            <div className="form-group">
                                <label className="d-block">Precio </label>
                                <Input
                                 name="precio"
                                 required
                                 value={articulo.precio}
                                 onChange={this.handeInputChange}
                                 
                             
                              
                                />
                            </div>
                        </div>
                        <div className="col-2 col-md-1"> 
                         <UncontrolledDropdown className='d-inline-block select-custom'>
              <DropdownToggle caret>
                $
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio1)}} >$ {this.state.info.precio1}</DropdownItem>
                 <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio2)}} >$ {this.state.info.precio2}</DropdownItem>
                  <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio3)}} >$ {this.state.info.precio3}</DropdownItem>
                   <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio4)}} >$ {this.state.info.precio4}</DropdownItem>
                    <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio5)}} >$ {this.state.info.precio5}</DropdownItem>
                    <DropdownItem onClick={ (opcion) => { this.cambio_precio(this.state.info.precio6)}} className='ultimo' >$ {this.state.info.precio6}</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
                         </div>

                            <div className="form-group col-12 col-md-12 ">
                                <label  className=" col-form-label">Descuento : </label>
                                  <input type="number" className="form-control" 
                                    name="descuento"
                                    required
                                    value={articulo.descuento}
                                    onChange={this.handeInputChange}
                                   />
                            </div>

                           
                            <div className="form-group col-12 col-md-12 ">
                                <label  className=" col-form-label">Unidad Sat : </label>
                                  <input type="text" className="form-control" 
                                    name="unidad"
                                    required
                                    disabled
                                    value={articulo.unidad}
                                    onChange={this.handeInputChange}
                                   />
                            </div>
                            


                                  </div>
                                 </div>
                                 <div className='col-6'>
                                  <div className='row'>
                                  <Col md="3">
                                  <div className="form-group pl20 m-0 " >
                                    <input type="checkbox" className="form-check-input" id="check_exento"
                                    name="excento_iva"
                                    
                                    checked={articulo.excento_iva}
                                    onChange={this.handeInputChange}/>
                                    <label className="form-check-label" htmlFor="check_exento">Exento</label>
                                  </div>
                               </Col>
                               <Col md="9">
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">I.V.A</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control" 
                                      name="iva"
                                      
                                      value={articulo.iva}
                                      onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               <Col md="3">
                                  <div className="form-group pl30 m-0 " >
                                    <input type="checkbox" className="form-check-input" id="check_cuota_fija_ieps"
                                    name="cuota_fija_ieps"
                                    
                                    checked={articulo.cuota_fija_ieps}
                                    onChange={this.handeInputChange} />
                                    <label className="form-check-label" htmlFor="check_cuota_fija_ieps">Cuota Fija</label>
                                  </div>
                               </Col>
                               <Col md="9">
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">I.E.P.S.</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control"
                                      name="ieps"
                                    required={articulo.cuota_fija_ieps}
                                    value={articulo.ieps}
                                    onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               <Col md="3" className='d-none'>
                                  <div className="form-group pl30 m-0 " >
                                    <input type="checkbox" className="form-check-input" id="check_cuota_fija_otro"
                                    name="cuota_fija_otro"
                                    
                                    checked={articulo.cuota_fija_otro}
                                    onChange={this.handeInputChange} />
                                    <label className="form-check-label" htmlFor="check_cuota_fija_otro">Cuota Fija</label>
                                  </div>
                               </Col>
                               <Col md="9" className='d-none'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Impuesto 3</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control" 
                                      name="impuesto_otro"
                                      
                                      value={articulo.impuesto_otro}
                                      onChange={this.handeInputChange} /> 
                                    </div>
                                  </div>
                               </Col>
                               <Col md="3"></Col>
                               <Col md="9" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Cantidad Fiscal</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control" 
                                        name="cantidad_fiscal"
                                         required={articulo.cuota_fija_ieps}
                                        value={articulo.cantidad_fiscal}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               <Col md="3"></Col>
                               <Col md="9" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Retencion I.S.R</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control" 
                                      name="retencion_isr"
                                      
                                      value={articulo.retencion_isr}
                                      onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>
                               <Col md="3"></Col>
                               <Col md="9" className='mb-1'>
                                 <div className="form-group row m-0">
                                    <label htmlFor="txt_registro" className="col-md-4 col-form-label m-0 p-0">Retencion I.V.A.</label>
                                    <div className="col-md-6">
                                      <input type="text" className="form-control" 
                                        name="retencion_iva"
                                        
                                        value={articulo.retencion_iva}
                                        onChange={this.handeInputChange} />
                                    </div>
                                  </div>
                               </Col>

                                  </div>
                                 </div>
                                
                              </div>
                            </div>





                            
                           



                            



                            
                            

                            
                            </Row>
                            </Row>
                            {articulo.serie ==1 &&
      <div className='row justify-content-center'>
        <div className='col-8'>
          <div className='row'>
            <div className="col-md-12 text-right">
              <div className="form-group">
                {articulo.serie ==1 &&
                  <Button color="primary" className="" 
                  disabled={this.state.submit}
                  style={{    position: 'relative', bottom: '-13px'}}
                  onClick={ (button) => { this.toggle_modal(20,"Series","articulo_id") } }>
                  Buscar
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    } {articulo.serie ==1 &&
      <div className="row justify-content-center">
        <div className="col-12 col-md-8"> 
          <ReactTable
          data      = {articulo.series}
          className = "-striped -highlight"
          columns   = {[
            {
              Header: 'Controles',
              filterable: false,
              sortable: false,
              maxWidth:250,minWidth:250,
              Cell: (row) =>
              {
                return(
                  <div className="text-left">
                  <Button
                  color="danger" 
                  className='m-0 p-2 px-3'

                  onClick={(evt)=>this.delete(evt, row.index)}>
                  Eliminar
                  </Button> 
                  </div>
                  )
              }
            },

            {Header: 'Serie', accessor: 'serie',minWidth:250},
            ]}
            filterable 
            pageSizeOptions= {[5]}  defaultPageSize={5} 
            defaultFilterMethod={filterCaseInsensitive}
            />        
          </div>
        </div>
      }
                            </Form>  
                          </ModalBody>
                          <ModalFooter>
                    

                   
                </ModalFooter>
                {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}


                
                  origen={parseInt(this.props.almacen_id)}
                  documento={parseInt(0)}
                  retornoserie={this.push}
                  articulo={parseInt(this.state.articulo.articulo_id)}
                  
              />
          }
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
         auth : state.auth,
    }
};

export default connect(mapStateToProps, actions)(TicketModal)