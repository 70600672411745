import axios from 'axios';

 // export const API_URL = 'http://apierp.wenatives.com/api';
 // export const API_UR = 'http://apierp.wenatives.com/';

 // export const API_URL = 'http://api.wenatives.com.mx/api';
 // export const API_UR = 'http://api.wenatives.com.mx';

 // export const API_URL = 'https://api.vegex.wenatives.com/api';
 // export const API_UR = 'https://api.vegex.wenatives.com';


 export const API_URL = 'https://apirosarito.wenatives.com/api';
 export const API_UR = 'https://apirosarito.wenatives.com';


 //  export const API_URL = 'http://localhost:8000/api';
 // export const API_UR = 'http://localhost:8000';
 
// http://api.vegex.wenatives.com/
 //carpeta para el static  paper-dashboard-react

 //9-7Ee2,F~#Y=

 //laravel_nomina/storage/app/xmls
export const request = axios.create({
	baseURL 		: API_UR,
	responseType 	: 'json'
});

export const api = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken }

	});
}

export const api_formdata = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'json',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}

export const api_descarga = () => {

	let token = localStorage.getItem('session_token_portal');

	token = JSON.parse(token);

	let AuthorizationToken = token.token_type+" "+token.access_token;

	return axios.create({
		baseURL 		: API_URL,
		responseType 	: 'blob',
		headers 		: {'Authorization': AuthorizationToken,
							'Content-Type': 'multipart/form-data', }

	});

}