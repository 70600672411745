import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/inventariofisico';
import {api } from '../../actions/_request';
import swal from 'sweetalert2';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  TabContent, TabPane, Nav, NavItem, NavLink, CardText
} from "reactstrap";
import classnames from 'classnames';
import ModalTablas from '../../components/catalogos/modal_tablas';
import { debounce } from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";

import Select from 'react-select';



class Proveedor extends Component {
  constructor(props){
    super(props);
    this.state = {
      articulo:{
        'fecha':'',
        'referencia':'',
        'tipo':1,
        'concepto':'',
        'origen_id':0,
        'destino_id':0,
        'documento_id':0,
      },
      insumo:{
        'codigo':'',
        'descripcion':'',
         'costo':'',
          'unidad':'',
           'insumo_id':'',
         'existencia':'',
           'fisico':'',
           'id':null,
           

      },
      productos:[],
      action : 'save',
      submit:false,
      activeTab: '1',
      modal:false,
      tabla_modal:0,
      titulo:'',
      campo_modal:'',
      modal_productos:false,
      tipo:{label: 'Entrada', value: 1},
    }
     this.handleSubmit = this.handleSubmit.bind(this);
     this.handeInputChange = this.handeInputChange.bind(this);
     this.toggle_modal= this.toggle_modal.bind(this); 
     this.retorno= this.retorno.bind(this);   
     this.ChangeCode= this.ChangeCode.bind(this);  
     this.handleClose= this.handleClose.bind(this);
     this.toggle_tab= this.toggle_tab.bind(this);
     this.toogle_productos= this.toogle_productos.bind(this);
     this.retornoProducto = this.retornoProducto.bind(this);
     this.delete = this.delete.bind(this);

     this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('value---->',value)
        console.log('name---->',name)
        let self=this;

          swal.queue([{
        title: 'Al Cambiar De tipo ',
        text: " Se Limpiara la informacion general y los productos",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
            self.setState({
                [name]: select,
                productos:[],
                codigo_origen_id:'',
                codigo_destino_id:'',
                codigo_documento_id:'',
                label_origen_id:'',
                label_destino_id:'',
                label_documento_id:'',
                articulo: {
                    ...self.state.articulo,
                    [name]: value,
                    'origen_id':0,
                    'destino_id':0,
                    'documento_id':0,
                }
            });
        }
      }])
    }
  delete(evt,index){
      let {productos} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar El producto: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                productos.splice(index,1);
        self.setState({
          
           productos:productos,
          });
        }
      }])


         
    }

  retornoProducto(obj){
       let {productos} = this.state;
       var indiceproducto= this.state.indiceproducto;
       console.log('retorno producto---->',obj);
       let bandera=true;
       // console.log('indice--->',indiceproducto)
       if(indiceproducto==null){
        for (var i = productos.length - 1; i >= 0; i--) {
        if(obj.articulo_id==productos[i].articulo_id)
            {
             bandera=false;
             swal.fire("Oops..", "Producto ya ingresado");
             break;
            }
       }

       if(bandera)
        productos.push(obj);
      else
        return


       }else{
        productos[indiceproducto]=obj;
       }

       this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : null,  
            productos:productos,
        })


  }
  toogle_productos(evt,indice=null){  
    let {articulo}=this.state;
    if(parseInt(articulo.origen_id)>0){
        this.setState({
            modal_productos : !this.state.modal_productos,       
            indiceproducto : indice,  
        });
    }else{
      swal.fire("Oops..", "Selecciona un Almacen");
    }
        
  }
  toggle_tab(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
  }
  toggle_modal(index=0,catalogo="",campo="",tipo=0,nivel=0){  
        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
  } 
  retorno(objeto,campo,vacio=0,valor=""){
        let label="";
        let codigo="";
        let id="";
        let {articulo} = this.state;
        let {productos} = this.state;

        let self =this;
        if(vacio==0){
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;
          
        }else{
          
        }

        if(campo=='almacen_id'){

            if(productos.length>0){
              self.setState({
                            modal:false,         
                        });
                
             swal.queue([{
                title: 'Al Cambiar De almacen ',
                text: " Se Limpiaran los productos",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar!',
                cancelButtonText: 'Cancelar!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  return api().get('/get_insumos_almacen/'+id)
                              .then(function(response)
                              {    
                                  console.log('response-->',response)    
                                  if(response.status === 200)
                                  {
                                     self.setState({
                                          articulo: {
                                              ...self.state.articulo,
                                              [campo]: id
                                          },    
                                          productos:response.data.datos,  
                                          ['label_'+campo]:label,
                                          ['codigo_'+campo]:codigo,
                                          modal:false,         
                                      })
                                  }
                                    
                              })
                              .catch((error) => {
                                console.log('error---->',error)
                                swal.insertQueueStep({
                                  type: 'error',
                                  title: 'Solicitar ayuda a un Administrador',
                                })
                              })




                    
                }
              }])
            }else{
                swal.queue([{
                title: 'Cargando Insumos ',
                text: " ",
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar!',
                cancelButtonText: 'Cancelar!',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  return api().get('/get_insumos_almacen/'+id)
                              .then(function(response)
                              {    
                                  console.log('response-->',response)    
                                  if(response.status === 200)
                                  {
                                     self.setState({
                                          articulo: {
                                              ...self.state.articulo,
                                              [campo]: id
                                          },    
                                          productos:response.data.datos,  
                                          ['label_'+campo]:label,
                                          ['codigo_'+campo]:codigo,
                                          modal:false,         
                                      })
                                  }
                                    
                              })
                              .catch((error) => {
                                console.log('error---->',error)
                                swal.insertQueueStep({
                                  type: 'error',
                                  title: 'Solicitar ayuda a un Administrador',
                                })
                              })




                    
                }
              }])
                swal.clickConfirm();
            }


          






          }else{

            self.setState({
            articulo: {
                ...this.state.articulo,
                [campo]: id
            },         
            ['label_'+campo]:label,
            ['codigo_'+campo]:codigo,
            modal:false,         
        })
          }



        
  }
  ChangeCode(event) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      // console.log('name---->',name);
      // console.log('value---->',value);
      this.setState({
          [name] : value
      });
  }
  handleClose (event,tabla,campo) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      let self =this;


 
        if(value!=""){
          api().get(`${tabla}/${value}`)
          .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                       
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                     self.retorno(response.data,campo,vaciar,value);        
                   
                }

            });
        }else{
            self.retorno([],campo,1,value); 
        }
  }


    handleCloseInsumo (event) {
      const target    = event.target;
      const value     = target.type === 'checkbox' ? target.checked : target.value;
      const name      = target.name;
      let self =this;


 
        if(value!=""){
          this.buscar_insumo(value);
        }else{
            
        }
  }
  editar_insumo=(i)=>{
    let {productos} = this.state;
      this.setState({
           
            insumo:{...productos[i]},
          })

  }
  delete=(evt,index)=>{
      let {productos} = this.state;   
      
      let submit=true;
        /*peticion para agregar*/
        let self = this;

         swal.queue([{
        title: 'Favor de Confirmar ',
        text: " Se eliminar El producto: ",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar!',
        cancelButtonText: 'Cancelar!',
        showLoaderOnConfirm: true,
        preConfirm: () => {
                productos.splice(index,1);
        self.setState({
          
           productos:productos,
          });
        }
      }])


         
    }
handeInputChangeInsumo=(event)=>{
    let {insumo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    insumo[name] = value;
    // console.log('insumo handeInputChange---->',insumo)
    this.setState({
        insumo : insumo
    });
  }

  buscar_insumo=(value)=>
  {


    let _self = this;
    let {productos} = this.state;
    let busqueda=false;
    /*buscamos si el insumo se encuentra en la lista*/
    for (var i = productos.length - 1; i >= 0; i--) {
     if( productos[i].codigo==value){
      busqueda=true;
      console.log('entro al buscar',productos[i])
      _self.setState({
           
            insumo:{...productos[i]},
          })

      break;
     }
    }

    if(busqueda==false){
      api().get(`/get_insumo_alamacen_fisico/${value}`)
          .then(function(response)
            {                  
                if(response.status === 200)
                { 
                    let vaciar=0;
                     if(Object.keys(response.data).length > 0){
                        _self.setState({
           
                            insumo:response.data,
                          })
                       
                     }  else{
                         vaciar=1
                         swal.fire("Oops..", "No Existe el Codigo");
                     }
                    
                   
                }else{
                   swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                }

            });
    }

  }



  componentDidMount(){
    let _self = this;
    let {articulo} = this.state;
    let {params} = this.props.match;

    if(params.id) {
      api().get("/getinventario_fisico/"+params.id)
      .then(function(response){
        if(response.status === 200){
          articulo = response.data.compra;
          _self.setState({
            articulo : response.data.inventario,
            label_almacen_id : response.data.inventario.label_almacen_id,
            productos : response.data.productos,
            action : 'update'
          })
        }

      });
    }




  }
  handeInputChange(event){
    let {articulo} = this.state;
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    articulo[name] = value;
    // console.log('articulo handeInputChange---->',articulo)
    this.setState({
        articulo : articulo
    });
  }

  handleSubmitInsumo=(evt)=>{
     evt.preventDefault();

      let _self = this;
    let {productos,insumo} = this.state;
    let busqueda=false;
    /*buscamos si el insumo se encuentra en la lista*/
    for (var i = productos.length - 1; i >= 0; i--) {
     if( productos[i].codigo==insumo.codigo){
      busqueda=true;
       productos[i]=insumo;
      

      break;
     }
    }


    if(busqueda==false){
      productos.push(insumo);
    }


    _self.setState({
           
            productos:productos,
            insumo:{
              'codigo':'',
              'descripcion':'',
               'costo':'',
                'unidad':'',
                 'insumo_id':'',
               'existencia':'',
                 'fisico':'',
                 'id':null,
            },
          })


  }

  handleSubmit(evt){
    evt.preventDefault();
    let _self = this;
    let {articulo,action,productos} = this.state;
    let save = true;
    // _self.setState({
    //         submit : true
    //       })
    articulo.productos=productos;
    console.log('compra------->',articulo)
    let bandera_pedimento=false;
      if(productos.length == 0)
        {
         
        swal.fire("Alerta!", 'Favor de Agregar Insumos', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
      }
//console.log('validacion de proveedor')

    if(parseInt(articulo.almacen_id)==0){
     
        swal.fire("Alerta!", 'Asigne un almacen', "warning");
        save=false;
        _self.setState({
            submit : false
          })
        return;
    }
    this.props.saveupdate(articulo, (response) => {
                if(response.status){
                  if(response.data.estado){
                    //console.log('actualizar')
                    swal.fire("Bien hecho!", "La Informacion fue Guardada con éxito!", "success").then((result) => {
                        _self.props.history.push('/app/mov/invfisico');
                        });
                    
                    //  _self.setState({
                    //   articulo : response.data.data,
                    //   action : 'update',
                    //   submit : false
                    // })
                    

                  }else{
                    swal.fire("Alerta!", response.data.mensaje, "warning");
                    _self.setState({
                        submit : false
                      });
                  }
                }else{
                  //console.log('error')
                  swal.fire({
                            type: 'error',
                            title: 'Solicitar ayuda a un Administrador',
                          })
                  _self.setState({
                        submit : false
                      });
                }
              });
  }

  regresar=(evt)=>
  {
    evt.preventDefault();
    this.props.history.push('/app/mov/invfisico');
  }

  render() {
     let {submit,articulo} = this.state;
     console.log('state---->',this.state)
    return (
        <div className="content ">
          <Row>
            <Col md="12">
            
              <Card className="card-user">
              <Form onSubmit={this.handleSubmit}>
                <CardHeader>
                
                 <Row>
                      <Col className="update  text-right">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                        <Button
                          className="btn-round"
                          color="warning"
                          onClick={this.regresar}>
                          Cancelar
                        </Button>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                <Row>
                <Col md="12">
                  <div className='row'>
                  <div className="form-group col-12 col-md-4 col-lg-2">
                    <label  className=" col-form-label">Fecha : </label>
                      <input type="date" className="form-control" 
                        name="fecha"
                        required
                        value={articulo.fecha}
                        onChange={this.handeInputChange}
                       />
                  </div>
                 <Col sm='2' md="2">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Almacen </label>
                                  <input type="text" className="form-control" 
                                  name="label_almacen_id"
                                  required
                                  value={this.state.label_almacen_id}
                                  onChange={this.ChangeCode} 
                                  readOnly
          
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos ajuste_catalgo"
                                  color="secondary"

                                  onClick={ (button) => { this.toggle_modal(16,"Almacenes","almacen_id") } }>
                                  +
                                </Button>
                            </Col>
     
                  </div>
                   <div className='row'>
                  <div className="form-group col-12 ">
                    <label  className=" col-form-label">Concepto : </label>
                      <input type="text" className="form-control" 
                        name="concepto"
                        required
                        value={articulo.concepto}
                        onChange={this.handeInputChange}
                       />
                  </div>
                   <div className="form-group col-12 ">
                    <label  className=" col-form-label">Nota : </label>
                      <textarea type="text" className="form-control" 
                        name="nota"
                        rows="4"
                        maxlength="250"
                        value={articulo.nota}
                        onChange={this.handeInputChange}
                       />
                  </div>



                  </div>
      
                   
                </Col>
                </Row>
                 
                </CardBody>
                  </Form>
                  <CardBody>

                  <Row>
            <Col md="12">
            
              
               
               <Form onSubmit={this.handleSubmitInsumo}>
               <Row>
                 <div className="form-group col-12  col-md-3 col-lg-2">
                    <label  className=" col-form-label">Clave : </label>
                       <input type="text" className="form-control" 
                        name="codigo"
                        required
                        onChange={this.handeInputChangeInsumo} 
                        value={this.state.insumo.codigo}
                        onKeyPress={event => {
                                      
                                      if (event.key === 'Enter') {
                                       event.preventDefault();
                                        this.handleCloseInsumo(event)
                                      }
                                    }}
                       />
                  </div>
                  <div className="form-group col-12  col-md-6">
                    <label  className=" col-form-label">descripcion : </label>
                       <input type="text" className="form-control" 
                        name="descripcion"
                        required
                        readOnly
                        value={this.state.insumo.descripcion}
                        onChange={this.handeInputChangeInsumo}
                       />
                  </div>
                  <div className='col-12'></div>
                  <div className="form-group col-12  col-md-3 col-lg-2">
                    <label  className=" col-form-label">unidad : </label>
                       <input type="text" className="form-control" 
                        name="unidad"
                        required
                         readOnly
                        value={this.state.insumo.unidad}
                        onChange={this.handeInputChangeInsumo}
                       />
                  </div>
                  <div className="form-group col-12  col-md-3 col-lg-2">
                    <label  className=" col-form-label">costo : </label>
                       <input type="text" className="form-control" 
                        name="costo"
                        required
                         readOnly
                        value={this.state.insumo.costo}
                        onChange={this.handeInputChangeInsumo}
                       />
                  </div>
                  <div className="form-group col-12  col-md-3 col-lg-2">
                    <label  className=" col-form-label">existencia : </label>
                       <input type="text" className="form-control" 
                        name="existencia"
                        required
                         readOnly
                        value={this.state.insumo.existencia}
                        onChange={this.handeInputChangeInsumo}
                       />
                  </div>
                  <div className="form-group col-12  col-md-3 col-lg-2">
                    <label  className=" col-form-label">fisico : </label>
                       <input type="text" className="form-control" 
                        name="fisico"
                        required
                        value={this.state.insumo.fisico}
                        onChange={this.handeInputChangeInsumo}
                       />
                  </div>
                   <div className="form-group col-12  col-md-3 col-lg-2">
                   <label  className=" col-form-label d-block p-0">. </label>
                     <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                          disabled={submit}
                        >
                          Guardar
                        </Button>
                  </div>
                  </Row>
                    </Form>

                    <Row>
                  <div className='col-12'>

                    <ReactTable
                             getTrProps={(state, rowInfo, column) => {
                                  if(rowInfo === undefined){
                                          return{ }
                                        }else{
                                           return{
                                            onDoubleClick: (e) => {
                                                  e.preventDefault(); 
                                                  this.editar_insumo(rowInfo.index);
                                            }
                                        } 
                                    }
                              }}
                              className = "-striped -highlight"
                              columns   = {[
                                {
                                    Header: 'Controles',
                                    filterable: false,
                                    sortable: false,
                                    maxWidth:300,minWidth:300,
                                    Cell: (row) =>
                                    {
                                        return(

                                            <div className="text-center">

                                             {
                                                parseFloat(row.original.existencia)==0&&
                                                 <Button
                                                 color="danger" 
                                                 className=' btn-sm mr-1  '
                                                 
                                                 onClick={(evt)=>this.delete(evt, row.index)}>
                                                    Eliminar
                                                </Button> 
                                              }

                                                <Button
                                                 color="success" 
                                                 className="btn-sm" onClick={(e)=>{e.preventDefault(); 
                                                   this.editar_insumo(row.index); 
                                                }}>
                                                    Editar
                                                </Button>
                                               
                                            </div>
                                        )
                                    }
                                },
                                {Header: 'Codigo', accessor: 'codigo',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Descripción', accessor: 'descripcion'},
                                {Header: 'Unidad', accessor: 'unidad',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'existencia', accessor: 'existencia',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                {Header: 'Fisico', accessor: 'fisico',filterable: false,sortable: false,maxWidth:150,minWidth:150,style: { textAlign: 'center'}},
                                ]}
                              data      = {this.state.productos} 
                              filterable
                              defaultPageSize={5}
                            />
                  </div>

               </Row>
               

      
                   
               
            
              
            
            </Col>
          </Row>
                  </CardBody>
              </Card>
           
            </Col>
          </Row>


          









          {
            this.state.modal&&
            <ModalTablas
                  open={this.state.modal}
                  toggle={this.toggle_modal}                  
                  titulo={this.state.titulo}
                  catalogo={this.state.tabla_modal}
                  retorno={this.retorno}
                  campo={this.state.campo_modal}
                  nivel={this.state.nivel_modal}
                  tipo={this.state.tipo_modal}
                  
              />
          }

          

        </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        auth : state.auth,
        compra:state.compra
    }
};

export default connect(mapStateToProps, actions)(Proveedor)

