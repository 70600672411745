import React from 'react';
import { connect } from 'react-redux';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
    Button,Label,FormGroup, Input,InputGroup, InputGroupAddon
} from 'reactstrap';
// reactstrap components
import {
  
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
 
  Form,
  
  Row,
  Col,
  CardText
} from "reactstrap";
import classnames from 'classnames';
import _ from "lodash";
import Spinner from 'react-spinkit';

import swal from 'sweetalert2';

import * as actions from '../../actions/categorias';

import Select from 'react-select';

import {api, API_URL} from '../../actions/_request';


import ModalTablas from '../../components/catalogos/modal_tablas';

class ModalReportes extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = {
            tipo:{label: 'Insumos', value: 1},
            nivel:{label: '1', value: 1},
            action:'save',
            categoria:{
                tipo:1,
                nivel:1,
                nombre:'',
                id:'',
            },
            inventarios:[],
            submit:false,
            pages: null,
            loading: true,
            almacen_id:0,
            label_almacen_id:'Todos',
            fecha_final:'',
		
        };
      
  
	}

   

toggle_modal=(index=0,catalogo="",campo="",tipo=0,nivel=0)=>{  
        this.setState({
            modal : !this.state.modal,
            titulo:catalogo,
            tabla_modal:index,
            campo_modal:campo,
            tipo_modal:tipo,
            nivel_modal:nivel,         
        })
  }

  borrar=(campo,id=0,label='Todos',codigo='')=>{

            this.setState({
                  [campo]: id,                   
                  ['label_'+campo]:label,
                  ['codigo_'+campo]:codigo,
                  inventarios:[],
                  fecha_inicial:'',
                        
              })
  }
  retorno=(objeto,campo,vacio=0,valor="")=>{
        let label="";
        let codigo="";
        let id="";
        let {articulo} = this.state;
        let self =this;
  
          label=objeto.label;
          codigo=objeto.codigo;
          id=objeto.id;


          api().get(`/get_fechas_alamacen_fisico/${id}`)
              .then(function(response)
              {
                if(response.status === 200)
                {
                    console.log(response.data.inventario)
                  self.setState({
                  [campo]: id,                   
                  ['label_'+campo]:label,
                  ['codigo_'+campo]:codigo,
                  inventarios:response.data.inventario,
                  modal:false,         
              })
                }
              }); 








            


        
  }


   handleSelectChange(select, name) {
        const value = select === null ? null : select.value;
        console.log('select--->',select)
        this.setState({
           
                [name]: value
           
        });
    }
    handeInputChange=(event)=>{
    
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    

  
    this.setState({
      [name] : value
    });
  }


    reporte=()=>{
console.log('fecha_final--->',this.state.fecha_final)
        if(parseInt(this.state.almacen_id)>0){
            /*validamos que se seleccione un inventario fisico*/

             if(parseInt(this.state.fecha_inicial)>0){
                /*validamos que seleccionaran una fecha final*/
                if(this.state.fecha_final!=""){
                 window.open(`${API_URL}/pdf_existencia/${this.state.almacen_id}?&inventario=${this.state.fecha_inicial}&fecha_final=${this.state.fecha_final}`)
             }else{
                /*mensaje de error por no seleccionar una fecha final*/
                swal.fire("Oops..", "seleccionar una fecha final ");
             }
             }else{
                /*mensaje de error por no seleccionar un inventario fisico*/
                swal.fire("Oops..", "Seleccionar una fecha inicial ");
             }

        }else{
            /*todos*/
            window.open(`${API_URL}/pdf_existencia/0`)
        }
    }

	render()
	{

        console.log('estate Modal----->',this.state);
        let {submit,categoria,pages, loading}= this.state;
		
		return(
            /*charCode   es para poner texto en el boton para cerrar  pero aqui se uso para eliminarlo*/
			<Modal isOpen={this.props.open} toggle={this.props.toggle} charCode="X" className="default  ">				
                <ModalHeader >Reporte de Existencias</ModalHeader>
                <ModalBody> 
                <div className="content">
                <Row>
                <Col sm='8' md="8">
                              <div className="form-group row">
                                <div className="col-12">
                                 <label  className=" col-form-label">Almacen </label>
                                  <input type="text" className="form-control" 
                                  name="label_almacen_id"
                                  required
                                  value={this.state.label_almacen_id}
                                  onDoubleClick={(evt)=>{this.borrar("almacen_id"); }}
                                  onChange={this.ChangeCode} 
                                  readOnly
                                   />
                                </div>
                              </div>
                            </Col>
                            <Col sm='2' md="1" className='pt-2'>
                                <Button
                                  className="btn btn_catalogos ajuste_catalgo"
                                  color="secondary"

                                  onClick={ (button) => { this.toggle_modal(16,"Almacenes","almacen_id") } }>
                                  +
                                </Button>
                            </Col>

                            {this.state.almacen_id==0&&
                                <div className="col-12 "> 
                                <div className="form-group">
                                    <label className>Existencia Actual de todos los Almacenes</label>
                                   
                                </div>
                            </div>}
                             {this.state.almacen_id>0&&
                            <div className="col-12 col-md-6"> 
                                <div className="form-group">
                                    <label className>Fecha Inicial:</label>
                                    <Select
                                        placeholder=""
                                        type="text"
                                        name="fecha_inicial"
                                        options={this.state.inventarios}
                                        value={this.state.inventarios.filter(option => option.value == this.state.fecha_inicial)}
                                        
                                        onChange={ (select) => { this.handleSelectChange(select, 'fecha_inicial') } }
                                        clearable={false}

                                    />
                                </div>
                            </div>
                            }
                             {this.state.almacen_id>0&&
                            <div className="form-group col-12 col-md-6 ">
                                <label  className=" col-form-label p-0 m-0">Fecha Final : </label>
                                  <input type="date" className="form-control" 
                                    name="fecha_final"
                                    required
                                    value={this.state.fecha_final}
                                    onChange={this.handeInputChange}
                                   />
                              </div>
                          }

                </Row>

                </div>    
                </ModalBody>
               <ModalFooter>
                   
                       
                        <div>        
                        <Button color="secondary" onClick={this.reporte}>
                                Reporte
                            </Button>                    
                            <Button color="secondary" onClick={this.props.toggle}>
                                Cerrar
                            </Button>
                        </div>
                    
                </ModalFooter>   
                 {
                    this.state.modal&&
                    <ModalTablas
                          open={this.state.modal}
                          toggle={this.toggle_modal}                  
                          titulo={this.state.titulo}
                          catalogo={this.state.tabla_modal}
                          retorno={this.retorno}
                          campo={this.state.campo_modal}
                          nivel={this.state.nivel_modal}
                          tipo={this.state.tipo_modal}
                          
                      />
                  }            
            </Modal>
		);
	}
}

function mapStateToProps(state, ownProps) {
    return {
        ticket : state.ticket,
    }
};

export default connect(mapStateToProps, actions)(ModalReportes)