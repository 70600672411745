import React from "react";
import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import ModalCategorias from '../categorias/categorias.js';
import ModalModificadores from '../modificadores/modificadores.js';
import ModalDatosFacturacion from '../datosfactura/modal.js';
import ModalDatosParametros from '../parametros/modal.js';
import logo from "logo.svg";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
    this.sidebar = React.createRef();

    this.state = {
     
      activeTab: '',
      modal_categorias:false,
      modal_datos:false,
      modal_modificadores:false,
      
    }

     this.abrir= this.abrir.bind(this);
     this.toggle_modal= this.toggle_modal.bind(this);
  }


  toggle_modal(modal) {
    let name = this.state[modal];

    this.setState({
      [modal]: !name
    });

  }


  abrir(menu){
      var activeTab='';
      if ( parseInt( this.state.activeTab ) !== parseInt(menu) ) {
       activeTab= menu;
       
      }

      this.setState({
          activeTab: activeTab
        });

       
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    // console.log('state----->',this.state)
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <a
            href="http://www.wenatives.com" target="_blank"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="react-logo" />
            </div>
          </a>
          <a
            href="http://www.wenatives.com" target="_blank"
            className="simple-text logo-normal"
          >
            WeNatives
          </a>
        </div>
        <div className="sidebar-wrapper" ref={this.sidebar}>
          <Nav>
              <li className={this.activeRoute('/Dashboard') } >
                  <NavLink
                    to={'/app/Dashboard'}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={'nc-icon nc-single-copy-04'} />
                    <p>Dashboard</p>
                  </NavLink>
                </li>
                 <li className=''   onClick={() => { this.abrir(1); }} >
                  <a className="nav-link" >
                    <i className="nc-icon nc-single-copy-04" />
                    <p className= { parseInt( this.state.activeTab ) == 1?'dropdown-toggle abierto':'dropdown-toggle'} >Catalogos</p>
                    </a>
                 
                </li>
                {
                  parseInt( this.state.activeTab ) == 1 &&                
                  <li className={this.activeRoute('/app/facturas') } >
                          <NavLink to={'/app/facturas'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Facturas</p>
                          </NavLink>
                  </li>
                }

                {
                  parseInt( this.state.activeTab ) == 1 &&                
                  <li className={this.activeRoute('/app/clientes') } >
                          <NavLink to={'/app/clientes'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Clientes</p>
                          </NavLink>
                  </li>
                }
                {
                  parseInt( this.state.activeTab ) == 1 &&                
                  <li className={this.activeRoute('/app/recetas') } >
                          <NavLink to={'/app/recetas'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Recetas</p>
                          </NavLink>
                  </li>
                }


                {
                  parseInt( this.state.activeTab ) == 1 &&
                
            <li className=''
             onClick={() => { this.toggle_modal('modal_categorias'); }}>
                  <a className="nav-link pl-5" >
                    <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Clasificaciones</p>
                  </a>
                </li>
                }
                {
                  parseInt( this.state.activeTab ) == 1 &&
                
            <li className=''
             onClick={() => { this.toggle_modal('modal_modificadores'); }}>
                  <a className="nav-link pl-5" >
                    <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Modificadores</p>
                  </a>
                </li>
                }
             <li className=''   onClick={() => { this.abrir(2); }} >
                  <a className="nav-link" >
                    <i className="nc-icon nc-single-copy-04" />
                    <p className= { parseInt( this.state.activeTab ) == 2?'dropdown-toggle abierto':'dropdown-toggle'}>Movimientos</p>
                    </a>
                 
                </li>
                {
                  parseInt( this.state.activeTab ) == 2 &&                
                  <li className={this.activeRoute('/a_proveedores') } >
                          <NavLink to={'/app/a_proveedores'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">A. Proveedor</p>
                          </NavLink>
                  </li>
                }
                 {
                  parseInt( this.state.activeTab ) == 2 &&                
                  <li className={this.activeRoute('/a_cliente') } >
                          <NavLink to={'/app/a_cliente'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">A. Cliente</p>
                          </NavLink>
                  </li>
                }
                {
                  parseInt( this.state.activeTab ) == 2 &&                
                 <li className={this.activeRoute('/compras') } >
                          <NavLink to={'/app/compras'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Compras</p>
                          </NavLink>
                  </li>
                }
                {
                  parseInt( this.state.activeTab ) == 2 &&                
                  <li className={this.activeRoute('/mov/almacenes') } >
                          <NavLink to={'/app/mov/almacenes'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Almacen</p>
                          </NavLink>
                  </li>
                }
                {
                  parseInt( this.state.activeTab ) == 2 &&                
                  <li className={this.activeRoute('/mov/invfisico') } >
                          <NavLink to={'/app/mov/invfisico'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Inv. Fisico</p>
                          </NavLink>
                  </li>
                }
                {
                  parseInt( this.state.activeTab ) == 2 &&                
                  <li className={this.activeRoute('/mov/rendimiento') } >
                          <NavLink to={'/app/mov/rendimiento'} className="nav-link pl-5" >
                            <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Rendimientos</p>
                          </NavLink>
                  </li>
                }


                <li className=''   onClick={() => { this.abrir(3); }} >
                  <a className="nav-link" >
                    <i className="nc-icon nc-single-copy-04" />
                    <p className= { parseInt( this.state.activeTab ) == 3?'dropdown-toggle abierto':'dropdown-toggle'} >Parametros</p>
                    </a>
                 
                </li>
                {
                  parseInt( this.state.activeTab ) == 3 &&
                
            <li className=''
             onClick={() => { this.toggle_modal('modal_datos'); }}>
                  <a className="nav-link pl-5" >
                    <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Datos Facturacion</p>
                  </a>
                </li>
                }

                {
                  parseInt( this.state.activeTab ) == 3 &&
                
            <li className=''
             onClick={() => { this.toggle_modal('modal_parametros'); }}>
                  <a className="nav-link pl-5" >
                    <i className={'nc-icon nc-single-copy-04'} /> <p className="menu_expandible">Global</p>
                  </a>
                </li>
                }











            {this.props.routes.map((prop, key) => {
              return (
                <li
                  className={
                    this.activeRoute(prop.layout + prop.path) +
                    (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                  >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              );
            })}
          </Nav>
        </div>

         { this.state.modal_modificadores&&
                <ModalModificadores
                    open={this.state.modal_modificadores}
                    toggle={() => { this.toggle_modal('modal_modificadores'); }}  
                />
            }
        { this.state.modal_categorias&&
                <ModalCategorias
                    open={this.state.modal_categorias}
                    toggle={() => { this.toggle_modal('modal_categorias'); }}  
                />
            }
             { this.state.modal_parametros&&
                <ModalDatosParametros
                    open={this.state.modal_parametros}
                    toggle={() => { this.toggle_modal('modal_parametros'); }}  
                />
            }

             { this.state.modal_datos&&
                <ModalDatosFacturacion
                    open={this.state.modal_datos}
                    toggle={() => { this.toggle_modal('modal_datos'); }}  
                />
            }
      </div>
    );
  }
}

export default Sidebar;
